import { findIndex } from "lodash";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import "./tabPanel.css";
import theme from "../../theme";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export type TabConfig = {
  label: string;
  path: string;
  disabled: boolean;
};

export type SimpleTabsProps = {
  tabConfigs: TabConfig[];
  activePath: string;
  onClick: (tabConfig: TabConfig) => void;
};

function SimpleTabs({
  tabConfigs,
  activePath,
  onClick,
}: SimpleTabsProps): JSX.Element {
  // if path is not found, default to the first tab
  const activeTab = findIndex(tabConfigs, { path: activePath });

  return (
    <div
      style={{
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginTop: "15px",
      }}
    >
      <AppBar position="static" className="tab-bar">
        <Tabs
          value={activeTab === -1 ? 0 : activeTab}
          aria-label="simple tabs example"
        >
          {tabConfigs.map((tabConfig, index) => {
            const { label, path, disabled } = tabConfig;
            return (
              <Tab
                disabled={disabled}
                key={label}
                label={label}
                {...a11yProps(index)}
                onClick={() => onClick(tabConfig)}
                data-testid={`tab-${path}`}
              />
            );
          })}
        </Tabs>
      </AppBar>
    </div>
  );
}

export default SimpleTabs;
