import "./groupInfo.css";
import BrinkTypography from "../../../shared/brinkTypography";
import { Group } from "../../../types";
import SharedGroupInfo from "../../../shared/groupInfo";

type PropTypes = {
  group: Group;
};

const GroupInfo = ({ group }: PropTypes): JSX.Element => {
  const { id, name, address, contact } = group;

  return (
    <div className="info-container">
      <BrinkTypography variant="h6">
        <b>Group</b>
      </BrinkTypography>
      <SharedGroupInfo
        id={id}
        name={name}
        address={address}
        contact={contact}
      />
    </div>
  );
};

export default GroupInfo;
