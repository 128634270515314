import { useState } from "react";
import { Box } from "@mui/material";
import { filter } from "lodash";
import SearchableDataTable from "../../shared/searchableTable";
import ServerButtonGroup from "./ServerButtonGroup";
import {
  Server,
  TableHeaderParams,
  PagingParams,
  SortParams,
  FilterParams,
} from "../../types";
import BrinkTypography from "../../shared/brinkTypography";
import ServerStatusCircle from "../../shared/serverStatusCircle";
import {RefEntity, buildUrl} from "../../shared/urlBuilder/adminPortal";

type ServersData = {
  servers: Server[];
  totalCount: number;
};

type PropTypes = {
  pagedServers: ServersData;
  pagingParams: PagingParams;
  onServerClicked: (selected: Server) => void;
  handlePageChange: (pageNumber: number, pageSize: number) => void;
  handleSortChange: (sortParams: SortParams) => void;
  handleFilterChange: (filterParams: FilterParams) => void;
};

const ServersTable = ({
  pagedServers,
  onServerClicked,
  pagingParams,
  handlePageChange,
  handleSortChange,
  handleFilterChange,
}: PropTypes): JSX.Element => {
  const [serversState, setServersState] = useState(pagedServers.servers);
  const [serverGroupState, setServerGroupState] = useState("All");

  const { servers, totalCount } = pagedServers;

  // const selectGroup = (selected: string) => {
  //   if (selected === "All") {
  //     setServersState(pagedServers.servers);
  //   } else {
  //     const filteredServers = filter(
  //       pagedServers.servers,
  //       ({ environment }) => environment === selected
  //     );

  //     setServersState(filteredServers);
  //   }
  //   setServerGroupState(selected);
  // };

  const changeSearchQuery = (filterParams: FilterParams) => {
    // Reset environment filter whenver search is performed
    // setServerGroupState("All");
    // const filteredServers = filter(servers, ({ name }) => {
    //   return name.includes(filterParams.filterQuery);
    // });

    handleFilterChange(filterParams);
    // setServersState(filteredServers);
  };

  const selectRow = (selected: Server) => {
    onServerClicked(selected);
  };

  const serversData = servers.map((server) => ({
    ...server,
    displayName: () => {
      return (
        <span
          data-testid={`server-link-${server.id}`}
          role="button"
          tabIndex={0}
        >
          <a
            href={buildUrl(RefEntity.stacks, server.name)}
            target="_blank"
            rel="noreferrer"
          >
            {server.name}
          </a>
        </span>
      );
    },
    groupsCount: () => {
      return (
        <span
          onClick={() => selectRow(server)}
          data-testid={`selected-column-${server.id}`}
          role="button"
          tabIndex={0}
        >
          <BrinkTypography clickable>{server.groupsCount}</BrinkTypography>
        </span>
      );
    },
    serverStatus: () => {
      return <ServerStatusCircle serverStatus={server.status} />;
    },
  }));

  const tableHeaders: TableHeaderParams[] = [
    { title: "Stack", sortable: true, filterable: true, field: "name" },
    // { title: "Status", sortable: false, field: "status" },
    { title: "Groups", sortable: true, field: "groupsCount" },
    {
      title: "Locations",
      sortable: true,
      field: "locationsCount",
    },
    // {
    //   title: "Users",
    //   sortable: false,
    //   field: "adminUsersCount",
    // },
    // { title: "Version", sortable: true, filterable: true, field: "version" },
    // { title: "BMS", sortable: false, field: "bms" },
  ];

  const tableColumns = [
    "displayName",
    // "serverStatus",
    "groupsCount",
    "locationsCount",
    // "adminUsersCount",
    // "version",
    // "bms",
  ];

  return (
    <Box paddingTop={2}>
      {/* <ServerButtonGroup
        currentGroup={serverGroupState}
        selectGroup={selectGroup}
      /> */}
      <SearchableDataTable
        rows={serversData}
        pagingParams={pagingParams}
        tableHeaders={tableHeaders}
        tableColumns={tableColumns}
        tableLength={totalCount}
        changeSearchQuery={changeSearchQuery}
        changePagination={handlePageChange}
        changeSort={handleSortChange}
      />
    </Box>
  );
};

export default ServersTable;
