import { AxiosRequestConfig } from "axios";
import {
  Summary,
  Group,
  Location,
  PagedLocations,
  Server,
  AdminUser,
  AdminGroup,
  ServerAccess,
  EventLog,
  GroupAccess,
  UserRole,
  Integration,
  GlobalSetting,
  Access,
  PagedGroups,
  PagingParams,
  PagedServers,
} from "../types";
import HttpClient from "./HttpClient";

export type Options = {
  baseUrl: string;
  getAccessTokenAsync: () => Promise<string>;
};

export default class GlobalTopologyService extends HttpClient {
  private getAccessTokenAsync: () => Promise<string>;

  public constructor({ baseUrl, getAccessTokenAsync }: Options) {
    super(baseUrl);

    this.getAccessTokenAsync = getAccessTokenAsync;
    this.initializeRequestInterceptor();
  }

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      this.handleRequest,
      this.handleError
    );
  };

  private handleRequest = async (config: AxiosRequestConfig) => {
    const { headers, ...rest } = config;

    const accessToken = await this.getAccessTokenAsync();
    headers.Authorization = accessToken;
    headers.Accept = "application/json";

    return {
      headers,
      ...rest,
    };
  };

  public getServers = (pagingParams: PagingParams): Promise<PagedServers> =>
    this.instance.get(
      `/servers?pagenumber=${pagingParams.page}&pagesize=${pagingParams.size}${
        pagingParams.sortParams.sortField
          ? `&sortfield=${pagingParams.sortParams.sortField}&sortorder=${pagingParams.sortParams.sortOrder}`
          : ""
      }${
        pagingParams.filterParams.filterQuery
          ? `&filterquery=${pagingParams.filterParams.filterQuery}&filtercolumn=${pagingParams.filterParams.filterColumn}`
          : ""
      }`
    );

  public getSummary = (): Promise<Summary> =>
    this.instance.get("/servers/summary");

  public getIntegrations = (): Promise<Integration[]> =>
    this.instance.get("/servers/integrations");

  public getGlobalSettings = (): Promise<GlobalSetting[]> =>
    this.instance.get("/servers/globalSettings");

  public getServer = (serverId: string): Promise<Server> =>
    this.instance.get(`/servers/${serverId}`);

  public getServerAccess = (serverId: string): Promise<ServerAccess> =>
    this.instance.get(`/servers/${serverId}/access`);

  public getServerGroups = (
    serverId: string,
    pagingParams: PagingParams
  ): Promise<PagedGroups> =>
    this.instance.get(
      `/servers/${serverId}/groups?pagenumber=${pagingParams.page}&pagesize=${
        pagingParams.size
      }${
        pagingParams.sortParams.sortField
          ? `&sortfield=${pagingParams.sortParams.sortField}&sortorder=${pagingParams.sortParams.sortOrder}`
          : ""
      }${
        pagingParams.filterParams.filterQuery
          ? `&filterquery=${pagingParams.filterParams.filterQuery}&filtercolumn=${pagingParams.filterParams.filterColumn}`
          : ""
      }`
    );

  public getServerEventLogs = (serverId: string): Promise<EventLog[]> =>
    this.instance.get(`/servers/${serverId}/eventlogs`);

  public getGroups = (pagingParams: PagingParams): Promise<PagedGroups> =>
    this.instance.get(
      `/groups?pagenumber=${pagingParams.page}&pagesize=${pagingParams.size}${
        pagingParams.sortParams.sortField
          ? `&sortfield=${pagingParams.sortParams.sortField}&sortorder=${pagingParams.sortParams.sortOrder}`
          : ""
      }${
        pagingParams.filterParams.filterQuery
          ? `&filterquery=${pagingParams.filterParams.filterQuery}&filtercolumn=${pagingParams.filterParams.filterColumn}`
          : ""
      }`
    );

  public getGroup = (groupId: string): Promise<Group> =>
    this.instance.get(`/groups/${groupId}`);

  public getLocations = (
    groupId: string,
    pagingParams: PagingParams
  ): Promise<PagedLocations> =>
    this.instance.get(
      `/groups/${groupId}/locations?pagenumber=${pagingParams.page}&pagesize=${
        pagingParams.size
      }${
        pagingParams.sortParams.sortField
          ? `&sortfield=${pagingParams.sortParams.sortField}&sortorder=${pagingParams.sortParams.sortOrder}`
          : ""
      }${
        pagingParams.filterParams.filterQuery
          ? `&filterquery=${pagingParams.filterParams.filterQuery}&filtercolumn=${pagingParams.filterParams.filterColumn}`
          : ""
      }`
    );

  public getLocation = (
    groupId: string,
    locationId: string
  ): Promise<PagedLocations> =>
    this.instance.get(`/groups/${groupId}/locations/${locationId}`);

  public getGroupAccess = (groupId: string): Promise<GroupAccess> =>
    this.instance.get(`/groups/${groupId}/access`);

  public getAccess = (): Promise<Access> => this.instance.get("/access");

  public getAdminUsers = (): Promise<AdminUser[]> =>
    this.instance.get("/access/adminUsers");

  public getAdminGroups = (): Promise<AdminGroup[]> =>
    this.instance.get("/access/adminGroups");

  public getAdminGroup = (adminGroupId: string): Promise<AdminGroup> =>
    this.instance.get(`/access/adminGroups/${adminGroupId}`);

  public getAdminGroupGroups = (
    adminGroupId: string,
    page = 10,
    size = 10
  ): Promise<PagedGroups> =>
    this.instance.get(
      `/access/adminGroups/${adminGroupId}/groups?pagenumber=${page}&pagesize=${size}`
    );

  public getAdminGroupUsers = (adminGroupId: string): Promise<AdminUser[]> =>
    this.instance.get(`/access/adminGroups/${adminGroupId}/users`);

  public getAdminGroupRoles = (adminGroupId: string): Promise<UserRole[]> =>
    this.instance.get(`/access/adminGroups/${adminGroupId}/roles`);
}
