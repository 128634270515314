/* eslint-disable import/prefer-default-export */
import { v4 } from "uuid";
import {
  Summary,
  Server,
  AdminGroup,
  AdminUser,
  Group,
  ServerAccess,
  UserRole,
  Setting,
  Configuration,
  Integration,
  GroupAccess,
  Location,
  GlobalSetting,
  EventLog,
  PagedGroups,
} from "../../types";

export const summary: Summary = {
  serversCount: 30,
  groupsCount: 2003,
  locationsCount: 11000,
  adminGroupsCount: 1800,
  adminUsersCount: 73282,
  integrationsCount: 282,
};

export const server: Server = {
  id: "17aff037-889e-4779-8168-cc80beab0944",
  name: "Admin",
  alias: "Admin 1",
  status: "Good",
  environment: "Production",
  version: "5.0E R4",
  bms: "5.0E R4",
  groupsCount: 100,
  locationsCount: 200,
  usersCount: 300,
  globalSettings: [],
};

export const settings: Setting[] = [
  {
    id: "1234",
    name: "Setting 1",
  },
];

export const globalSettings: GlobalSetting[] = [
  {
    id: "1234",
    name: "Global Setting 1",
  },
];

export const eventLogs: EventLog[] = [
  {
    id: v4(),
    message: "something happened",
    recordedAt: new Date(),
  },
];

export const configuration: Configuration = {
  apiToken: "92d9-185a7fd64876",
  encodeOrderIds: true,
  isFranchised: true,
  isLoyaltyEnabled: true,
};

export const integrations: Integration[] = [
  {
    id: "1",
    name: "Integration 1",
  },
];

export const location: Location = {
  id: v4(),
  name: "Group Location 1",
  businessDate: new Date(),
  dbName: "Group_Location_Db",
  onlineTerminalsCount: 1000,
  terminals: [],
  serverName: "Server 1",
  groupId: "7ca6ead0-ea88-4fc7-8c51-a0afd85c32ad",
};

export const locations: Location[] = [location];

export const groups: Group[] = [
  {
    id: "052354ea-06d1-4a49-92d9-185a7fd64876",
    name: "Dairy Queen 1",
    locationsCount: 100,
    onlineTerminalsCount: 10000,
    server,
    supportType: "Channel",
    locations: [],
    settings: [],
    integrations: [],
    notificationRecipients: [],
  },
];

export const adminGroup: AdminGroup = {
  id: "f56fd78d-3a26-48b3-b9b2-5f273d046eb1",
  name: "Admin Group 1",
  adminUsersCount: 100,
  groupsCount: 200,
  locationsCount: 300,
  server,
  lastModifiedAt: new Date(),
};

export const adminGroups: AdminGroup[] = [adminGroup];

export const adminUser: AdminUser = {
  id: "17aff037-889e-4779-8168-cc80beab0944",
  name: "John Smith 1",
  email: "john.smith@company.com",
  phone: "858-555-1212",
  isActive: true,
  adminGroup,
};

export const adminUsers: AdminUser[] = [adminUser];

export const serverAccess: ServerAccess = {
  adminUsers,
  adminGroups,
};

export const userRole: UserRole = {
  id: "f4bab815-937a-4102-b6b5-56446b9d4a87",
  name: "Support 1",
  usersCount: 30,
  isActive: true,
  groups,
  adminUsers,
};

export const groupAccess: GroupAccess = {
  adminGroups,
  adminUsers,
};

export const userRoles: UserRole[] = [userRole];

export const group: Group = {
  id: "052354ea-06d1-4a49-92d9-185a7fd64876",
  name: "Dairy Queen 1",
  locationsCount: 100,
  onlineTerminalsCount: 10000,
  server,
  supportType: "Channel",
  locations: [],
  configuration,
  settings,
  integrations,
  notificationRecipients: adminUsers,
};

export const groupsData: PagedGroups = {
  totalCount: 12,
  groups,
};
