import "./groupInfo.css";
import BrinkTypography from "../../../shared/brinkTypography";
import { Configuration } from "../../../types";

type PropTypes = {
  configuration: Configuration;
};

const Config = ({ configuration }: PropTypes): JSX.Element => (
  <div className="info-container" data-testid="config-container">
    <BrinkTypography variant="h6">
      <b>Configuration</b>
    </BrinkTypography>
    <div className="info-row">
      <div className="info-title text-color">
        <BrinkTypography>API Token: </BrinkTypography>
      </div>

      <div className="info-value">
        <BrinkTypography>{configuration?.apiToken}</BrinkTypography>
      </div>
    </div>

    <div className="info-row">
      <div className="info-title text-color">
        <BrinkTypography>Loyalty: </BrinkTypography>
      </div>

      <div className="info-value">
        <BrinkTypography dataTestId="text-isLoyaltyEnabled">
          {configuration?.isLoyaltyEnabled ? "Enabled" : "Disabled"}
        </BrinkTypography>
      </div>
    </div>

    <div className="info-row">
      <div className="info-title text-color">
        <BrinkTypography>Encode Order ID: </BrinkTypography>
      </div>

      <div className="info-value">
        <BrinkTypography dataTestId="text-encodeOrderIds">
          {configuration?.encodeOrderIds ? "Yes" : "No"}
        </BrinkTypography>
      </div>
    </div>

    <div className="info-row">
      <div className="info-title text-color">
        <BrinkTypography>Franchised: </BrinkTypography>
      </div>

      <div className="info-value">
        <BrinkTypography dataTestId="text-isFranchised">
          {configuration?.isFranchised ? "Yes" : "No"}
        </BrinkTypography>
      </div>
    </div>
  </div>
);

export default Config;
