import DataLoadingContainer from "../../../shared/dataLoadingContainer";
import { AdminUser, QueryKeys } from "../../../types";
import UsersTable from "./UsersTable";

type PropTypes = {
  adminGroupId: string;
  getAdminGroupUsers: (adminGroupId: string) => Promise<AdminUser[]>;
};
const UsersContainer = ({
  adminGroupId,
  getAdminGroupUsers,
}: PropTypes): JSX.Element => {
  const queryKeys = [QueryKeys.GetAdminGroupUsers, adminGroupId];
  const queryFn = () => getAdminGroupUsers(adminGroupId);

  return (
    <DataLoadingContainer
      queryKeys={queryKeys}
      queryFn={queryFn}
      render={(data: AdminUser[]) => {
        return <UsersTable users={data} />;
      }}
    />
  );
};

export default UsersContainer;
