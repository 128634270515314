import { useParams } from "react-router";
import {
  Group,
  GroupAccess,
  PagedLocations,
  PagingParams,
  QueryKeys,
} from "../../types";
import GroupComponent from "./Group";
import DataLoadingContainer from "../../shared/dataLoadingContainer";
import BackButton from "../../shared/backButton";

type GroupParams = {
  groupId: string;
};

type PropTypes = {
  getGroup: (groupId: string) => Promise<Group>;
  getGroupAccess: (groupId: string) => Promise<GroupAccess>;
  getGroupLocations: (
    serverId: string,
    pagingParams: PagingParams
  ) => Promise<PagedLocations>;
};

const GroupContainer = ({
  getGroup,
  getGroupAccess,
  getGroupLocations,
}: PropTypes): JSX.Element => {
  const { groupId } = useParams<GroupParams>();

  const queryKeys = [QueryKeys.GetGroup, groupId];
  const queryFn = () => getGroup(groupId);

  return (
    <DataLoadingContainer
      queryKeys={queryKeys}
      queryFn={queryFn}
      render={(data: Group) => {
        return (
          <>
            <BackButton sendToRoute="groups" sendToTitle="Groups" />
            <GroupComponent
              group={data}
              getGroupAccess={getGroupAccess}
              getGroupLocations={getGroupLocations}
            />
          </>
        );
      }}
    />
  );
};

export default GroupContainer;
