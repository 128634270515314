import { AdminGroup } from "../../../types";
import GroupInfo from "../../../shared/groupInfo";
import BrinkTypography from "../../../shared/brinkTypography";

type PropTypes = {
  adminGroup: AdminGroup;
};

const Details = ({ adminGroup }: PropTypes): JSX.Element => {
  const { id, name, address, contact } = adminGroup;

  return (
    <div className="info-container">
      <BrinkTypography variant="h6">
        <b>Admin Group</b>
      </BrinkTypography>
      <GroupInfo id={id} name={name} address={address} contact={contact} />
    </div>
  );
};

export default Details;
