import { useParams } from "react-router";
import {
  AdminGroup,
  AdminUser,
  PagedGroups,
  QueryKeys,
  UserRole,
} from "../../types";
import AdminGroupComponent from "./AdminGroup";
import DataLoadingContainer from "../../shared/dataLoadingContainer";

type PropTypes = {
  getAdminGroup: (adminGroupId: string) => Promise<AdminGroup>;
  getAdminGroupGroups: (adminGroupId: string) => Promise<PagedGroups>;
  getAdminGroupUsers: (adminGroupId: string) => Promise<AdminUser[]>;
  getAdminGroupRoles: (adminGroupId: string) => Promise<UserRole[]>;
};

type AdminGroupParams = {
  adminGroupId: string;
};

const AdminGroupContainer = ({
  getAdminGroup,
  getAdminGroupGroups,
  getAdminGroupUsers,
  getAdminGroupRoles,
}: PropTypes): JSX.Element => {
  const { adminGroupId } = useParams<AdminGroupParams>();

  const queryKeys = [QueryKeys.GetAdminGroup, adminGroupId];
  const queryFn = () => getAdminGroup(adminGroupId);

  return (
    <DataLoadingContainer
      queryKeys={queryKeys}
      queryFn={queryFn}
      render={(data: AdminGroup) => {
        return (
          <AdminGroupComponent
            adminGroup={data}
            getAdminGroupGroups={getAdminGroupGroups}
            getAdminGroupUsers={getAdminGroupUsers}
            getAdminGroupRoles={getAdminGroupRoles}
          />
        );
      }}
    />
  );
};

export default AdminGroupContainer;
