import { GlobalSetting } from "../../types";

type PropTypes = {
  globalSettings: GlobalSetting[];
};

const GlobalSettings = ({ globalSettings }: PropTypes): JSX.Element => {
  return <>{JSON.stringify(globalSettings)}</>;
};

export default GlobalSettings;
