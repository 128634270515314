import TableCell from "@mui/material/TableCell";

export type BrinkTableCellProps = {
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
  dataTestId?: string;
  onClick?: () => void;
  className?: string;
};

const BrinkTableCell = ({
  dataTestId = null,
  children = null,
  className = null,
  onClick = () => null,
}: BrinkTableCellProps): JSX.Element => {
  return (
    <TableCell data-testid={dataTestId} onClick={onClick} className={className}>
      {children}
    </TableCell>
  );
};

export default BrinkTableCell;
