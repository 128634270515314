import { Group } from "../../../types";
import SupportInfo from "./SupportingGroup";
import Config from "./Configuration";
import NotificationRecipients from "./NotificationRecipients";
import GroupInfo from "./GroupInfo";

type PropTypes = {
  group: Group;
};

const Info = ({ group }: PropTypes): JSX.Element => {
  const { supportingAdminGroup, configuration, notificationRecipients } = group;

  return (
    <>
      <GroupInfo group={group} />
      <SupportInfo supportingGroup={supportingAdminGroup} />
      <Config configuration={configuration} />
      <NotificationRecipients notificationRecipients={notificationRecipients} />
    </>
  );
};

export default Info;
