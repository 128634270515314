import SimpleTabs, { TabConfig } from "../../../shared/simpleTab";

type PropTypes = {
  activePath: string;
  onClick: (tabConfig: TabConfig) => void;
};

const tabConfigs = [
  {
    label: "Groups",
    path: "groups",
    disabled: false,
  },
  {
    label: "Access",
    path: "access",
    disabled: true,
  },
  {
    label: "Global Settings",
    path: "globalSettings",
    disabled: true,
  },
  {
    label: "Event Log",
    path: "eventLog",
    disabled: true,
  },
];

const ServerTabs = ({ activePath, onClick }: PropTypes): JSX.Element => {
  return (
    <SimpleTabs
      tabConfigs={tabConfigs}
      activePath={activePath}
      onClick={onClick}
    />
  );
};

export default ServerTabs;
