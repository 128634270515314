import DataLoadingContainer from "../../../shared/dataLoadingContainer";
import { QueryKeys, UserRole } from "../../../types";
import RolesTable from "./RolesTable";

type PropTypes = {
  adminGroupId: string;
  getAdminGroupRoles: (adminGroupId: string) => Promise<UserRole[]>;
};

const RolesContainer = ({
  adminGroupId,
  getAdminGroupRoles,
}: PropTypes): JSX.Element => {
  const queryKeys = [QueryKeys.GetAdminGroupRoles, adminGroupId];
  const queryFn = () => getAdminGroupRoles(adminGroupId);

  return (
    <DataLoadingContainer
      queryKeys={queryKeys}
      queryFn={queryFn}
      render={(data: UserRole[]) => {
        return <RolesTable roles={data} />;
      }}
    />
  );
};

export default RolesContainer;
