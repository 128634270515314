/* eslint-disable react/jsx-no-duplicate-props */
import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "@mui/material";
import { FilterParams } from "../../types";

export type SearchBarProps = {
  changeSearchQuery: (filterParams: FilterParams) => void;
  filterColumn: string;
  filterQuery: string;
  placeHolder?: string;
};

const SearchBar = ({
  changeSearchQuery,
  filterColumn,
  filterQuery,
  placeHolder = "Search",
}: SearchBarProps): JSX.Element => {
  const [localFilterQuery, setFilterQuery] = useState(filterQuery);

  // Pathname is used for keeping localstorage saved searched unique as some pages both use filterColumn "name"
  const { pathname } = window.location;

  const checkLocalStorageForSearchQuery = () => {
    if (
      localStorage.getItem(`${pathname}-filter-query`) === null ||
      localStorage.getItem(`${pathname}-filter-column`) === null
    ) {
      return false;
    }
    return true;
  };

  const getFilterQueryFromLocalStorage = () => {
    const localStorageFilterQuery = localStorage.getItem(
      `${pathname}-filter-query`
    );

    if (localStorageFilterQuery === null) {
      return "";
    }

    return localStorageFilterQuery.toString();
  };

  const getFilterColumnFromLocalStorage = () => {
    const localStorageFilterColumn = localStorage.getItem(
      `${pathname}-filter-column`
    );

    if (localStorageFilterColumn === null) {
      return "";
    }

    return localStorageFilterColumn.toString();
  };

  const storeFilterQueryToLocalStorage = () => {
    localStorage.setItem(`${pathname}-filter-query`, localFilterQuery);
    localStorage.setItem(`${pathname}-filter-column`, filterColumn);
  };

  // When SearchBar loads check if there's anything in localStorage
  useEffect(() => {
    if (checkLocalStorageForSearchQuery()) {
      const storedFilterQuery = getFilterQueryFromLocalStorage();
      const storedFilterColumn = getFilterColumnFromLocalStorage();
      setFilterQuery(storedFilterQuery);
      if (
        storedFilterColumn !== filterColumn ||
        storedFilterQuery !== filterQuery
      ) {
        changeSearchQuery({
          filterQuery: storedFilterQuery,
          filterColumn: storedFilterColumn,
        });
      }
    }
  }, []);

  // When changeSearchQuery is called we should save the current query to local storage
  const performSearch = () => {
    storeFilterQueryToLocalStorage();
    changeSearchQuery({ filterQuery: localFilterQuery, filterColumn });
  };

  return (
    <div>
      <TextField
        label={placeHolder}
        variant="standard"
        value={localFilterQuery}
        size="small"
        inputProps={{
          "data-testid": `search-input-${filterColumn}`,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                data-testid={`search-button-${filterColumn}`}
                onClick={(e) => {
                  performSearch();
                }}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={(e) => setFilterQuery(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            performSearch();
          }
        }}
      />
    </div>
  );
};

export default SearchBar;
