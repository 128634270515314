import { useParams } from "react-router";
import DataLoadingContainer from "../../shared/dataLoadingContainer";
import {
  Server,
  ServerAccess,
  PagedGroups,
  EventLog,
  QueryKeys,
  PagingParams,
} from "../../types";
import ServerComponent from "./Server";
import BackButton from "../../shared/backButton";

type PropTypes = {
  getServer: (serverId: string) => Promise<Server>;
  getServerAccess: (serverId: string) => Promise<ServerAccess>;
  getServerEventLogs: (serverId: string) => Promise<EventLog[]>;
  getServerGroups: (
    serverId: string,
    pagingParams: PagingParams
  ) => Promise<PagedGroups>;
};

type ServerParams = {
  serverId: string;
};

const ServerContainer = ({
  getServer,
  getServerAccess,
  getServerGroups,
  getServerEventLogs,
}: PropTypes): JSX.Element => {
  const { serverId } = useParams<ServerParams>();
  const queryKeys = [QueryKeys.GetServer, serverId];
  const queryFn = () => getServer(serverId);

  return (
    <DataLoadingContainer
      queryKeys={queryKeys}
      queryFn={queryFn}
      render={(data: Server) => {
        return (
          <>
            <BackButton sendToRoute=" " sendToTitle="Servers" />
            <ServerComponent
              server={data}
              getServerAccess={getServerAccess}
              getServerGroups={getServerGroups}
              getServerEventLogs={getServerEventLogs}
            />
          </>
        );
      }}
    />
  );
};

export default ServerContainer;
