import BrinkTypography from "../../../../../shared/brinkTypography";
import "./stat.css";

type StatProps = {
  title: string;
  value: number;
};

function Stat({ title, value }: StatProps): JSX.Element {
  return (
    <div className="stat-container">
      <div className="stat">
        <BrinkTypography variant="h5" component="div">
          {value}
        </BrinkTypography>
        <BrinkTypography className="stat-text" component="div">
          {title}
        </BrinkTypography>
      </div>
    </div>
  );
}

export default Stat;
