import { createTheme } from "@mui/material/styles";
import { Shadows } from "@mui/material/styles/shadows";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0A3450",
    },
    secondary: {
      main: "#B8C1CB",
    },
    common: {
      white: "white",
    },
    action: {
      hover: "#f5f5f5",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  shadows: Array(25).fill("none") as Shadows,
});

export default theme;
