export enum RefEntity {
  locations = "locations",
  stacks = "stacks",
  groups = "groups",
}

export const buildUrl = (
  refEntity: RefEntity,
  stackName: string,
  groupId?: string,
  locationId?: string
): string => {
  const adminPortalUrlSuffix = process.env.LEGACY_ADMIN_PORTAL_URL_SUFFIX;

  let subDomain = stackName.toLocaleLowerCase();
  if (subDomain.endsWith("alp")) {
    subDomain = subDomain.replace("alp", "alpha");
  }

  let url = `https://${subDomain}${adminPortalUrlSuffix}`;

  switch (refEntity) {
    case RefEntity.stacks:
      url += "/Admin/Groups";
      break;
    case RefEntity.groups:
      url += `/Admin/Groups/GroupTools?gid=${groupId}`;
      break;
    case RefEntity.locations:
      url += `/Admin/Locations/LocationTools?groupId=${groupId}&locationId=${locationId}`;
      break;
    default:
      break;
  }

  return url;
};
