import SharedAccess from "../../shared/access";
import { AdminUser, AdminGroup, Server } from "../../types";

type PropTypes = {
  adminGroups: AdminGroup[];
  adminUsers: AdminUser[];
  onAdminGroupSelected: (selected: AdminGroup) => void;
  onServerSelected: (selected: Server) => void;
};

const Access = ({
  adminGroups,
  adminUsers,
  onAdminGroupSelected,
  onServerSelected,
}: PropTypes): JSX.Element => {
  return (
    <SharedAccess
      adminUsers={adminUsers}
      adminGroups={adminGroups}
      onAdminGroupSelected={onAdminGroupSelected}
      onServerSelected={onServerSelected}
    />
  );
};

export default Access;
