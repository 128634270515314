import { TableContainer, TablePagination } from "@mui/material";
import Table from "@mui/material/Table";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import TableHeader from "../searchableTable/tableComponents/TableHeader";
import TableRows from "../searchableTable/tableComponents/TableRows";
import { SortParams, TableHeaderParams } from "../../types";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    height: "100%",
    marginTop: "20px",
  },
});

export type BrinkTableProps<T> = {
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
  dataTestId?: string;
  rows?: T[];
  tableHeaders?: TableHeaderParams[];
  tableColumns?: string[];
  rowsPer?: number;
  renderAction?: boolean;
};

const BrinkTable = <T extends unknown>({
  dataTestId = null,
  children = null,
  rows = [],
  tableHeaders,
  tableColumns,
  rowsPer = 10,
  renderAction = true,
}: BrinkTableProps<T>): JSX.Element => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPer);
  const [sortParams, setSortParams] = useState<SortParams>({
    sortOrder: "asc",
    sortField: null,
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSort = (sortParam: SortParams) => {
    setSortParams(sortParam);
  };

  return (
    <>
      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          aria-label="sticky table"
          className="access-table"
          role="table"
          data-testid={dataTestId}
        >
          <TableHeader
            tableHeaders={tableHeaders}
            sortParams={sortParams}
            changeSort={handleSort}
            key="header"
          />
          <TableRows
            page={page}
            rowsPerPage={rowsPer}
            rows={rows}
            columns={tableColumns}
            key="header-row"
          />
        </Table>
        <TablePagination
          rowsPerPageOptions={[4, 10, 15]}
          count={rows?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component="div"
        />
        {children}
      </TableContainer>
    </>
  );
};

export default BrinkTable;
