import { Summary } from "../../../types";
import BaseHeader from "../../../shared/baseHeader";
import SummaryStats from "./summaryStats";
import RootTabs from "./RootTabs";

export type PropTypes = {
  summary: Summary;
  onClickTab: (path: string) => void;
};

const ServerHeader = ({ summary, onClickTab }: PropTypes): JSX.Element => {
  return (
    <BaseHeader
      onClickTab={onClickTab}
      render={(activePath, onClick) => (
        <>
          <SummaryStats summary={summary} />
          <RootTabs activePath={activePath} onClick={onClick} />
        </>
      )}
    />
  );
};

export default ServerHeader;
