import { Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { ElementType } from "react";
import "./clickableTypography.css";

export type BrinkTypographyProps = {
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
  color?:
    | "initial"
    | "inherit"
    | "primary"
    | "secondary"
    | "textPrimary"
    | "textSecondary"
    | "error";
  variant?: Variant | "inherit";
  className?: string;
  dataTestId?: string;
  component?: ElementType;
  key?: string;
  clickable?: boolean;
};

const BrinkTypography = ({
  dataTestId = null,
  component = null,
  className = null,
  children = null,
  color = "initial",
  variant = "inherit",
  clickable = false,
}: BrinkTypographyProps): JSX.Element => {
  return (
    <Typography
      className={`${clickable ? "clickable-typography" : ""} ${className}`}
      data-testid={dataTestId}
      component={component}
      variant={variant}
      color={color}
    >
      {children}{" "}
    </Typography>
  );
};

export default BrinkTypography;
