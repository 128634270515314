import TableRow from "@mui/material/TableRow";

export type BrinkTableRowProps = {
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
  dataTestId?: string;
  key?: string;
  hover?: boolean;
  role?: string;
  tabIndex?: number;
};

const BrinkTableRow = ({
  dataTestId = null,
  children = null,
  key = null,
  hover = false,
  role = null,
  tabIndex = null,
}: BrinkTableRowProps): JSX.Element => {
  return (
    <TableRow
      data-testid={dataTestId}
      key={key}
      hover={hover}
      role={role}
      tabIndex={tabIndex}
    >
      {children}
    </TableRow>
  );
};

export default BrinkTableRow;
