import { Setting } from "../../types";

type PropTypes = {
  settings: Setting[];
};

const Settings = ({ settings }: PropTypes): JSX.Element => {
  return (
    <div>
      <p>Group - Settings</p>
      <p>{JSON.stringify(settings)}</p>
    </div>
  );
};

export default Settings;
