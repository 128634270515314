import { Route, Switch } from "react-router-dom";
import GlobalTopologyService from "../../api/globalTopologyService";
import Access from "../access";
import AdminGroup from "../adminGroup";
import GlobalSettings from "../globalSettings";
import Group from "../group";
import Groups from "../groups";
import Integrations from "../integrations";
import Server from "../server";
import Servers from "../servers";

type PropTypes = {
  topologyClient: GlobalTopologyService;
};

const BodyContainer = ({ topologyClient }: PropTypes): JSX.Element => {
  return (
    <Switch>
      <Route path="/adminGroups/:adminGroupId">
        <AdminGroup
          getAdminGroup={topologyClient.getAdminGroup}
          getAdminGroupGroups={topologyClient.getAdminGroupGroups}
          getAdminGroupUsers={topologyClient.getAdminGroupUsers}
          getAdminGroupRoles={topologyClient.getAdminGroupRoles}
        />
      </Route>
      <Route path="/servers/:serverId">
        <Server
          getServer={topologyClient.getServer}
          getServerAccess={topologyClient.getServerAccess}
          getServerGroups={topologyClient.getServerGroups}
          getServerEventLogs={topologyClient.getServerEventLogs}
        />
      </Route>
      <Route path="/groups/:groupId">
        <Group
          getGroup={topologyClient.getGroup}
          getGroupAccess={topologyClient.getGroupAccess}
          getGroupLocations={topologyClient.getLocations}
        />
      </Route>
      <Route path="/access">
        <Access getAccess={topologyClient.getAccess} />
      </Route>
      <Route path="/groups">
        <Groups getGroups={topologyClient.getGroups} />
      </Route>
      <Route path="/integrations">
        <Integrations getIntegrations={topologyClient.getIntegrations} />
      </Route>
      <Route path="/globalSettings">
        <GlobalSettings getGlobalSettings={topologyClient.getGlobalSettings} />
      </Route>
      <Route path="/">
        <Servers getServers={topologyClient.getServers} />
      </Route>
    </Switch>
  );
};

export default BodyContainer;
