import BrinkTable from "../../../shared/brinkTable";
import { EventLog, TableHeaderParams } from "../../../types";

type PropTypes = {
  eventLogs: EventLog[];
};

const tableHeaders: TableHeaderParams[] = [
  { title: "Message", sortable: true, field: "message" },
  { title: "Date", sortable: true, field: "recordedAt" }
];
const tableColumns = tableHeaders.map(x => x.field);

const EventLogs = ({ eventLogs }: PropTypes): JSX.Element => {
  return (
    <BrinkTable
      rows={eventLogs}
      tableHeaders={tableHeaders}
      tableColumns={tableColumns}
      renderAction={false}
    />
  );
};

export default EventLogs;
