import { ReactNode, useState } from "react";
import { useHistory } from "react-router-dom";

export type PropTypes = {
  onClickTab: (path: string) => void;
  render: (
    activePath: string,
    onClick: (clickedTab: { label: string; path: string }) => void
  ) => ReactNode;
};

const getLastPart = (pathname: string) => {
  const splitPath = pathname.split("/");
  return splitPath[splitPath.length - 1];
};

const BaseHeader = ({ onClickTab, render }: PropTypes): JSX.Element => {
  const history = useHistory();

  const [activePathState, setActivePathState] = useState(
    getLastPart(history.location.pathname)
  );

  const onClick = (clickedTab: { label: string; path: string }) => {
    setActivePathState(clickedTab.path);
    onClickTab(clickedTab.path);
  };

  return <>{render(activePathState, onClick)}</>;
};

export default BaseHeader;
