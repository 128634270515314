import { FilterParams, PagingParams, Server, SortParams } from "../../types";
import ServersTable from "./ServersTable";

type ServersData = {
  servers: Server[];
  totalCount: number;
};

export type PropTypes = {
  pagedServers: ServersData;
  onServerClicked: (server: Server) => void;
  pagingParams: PagingParams;
  handlePageChange: (pageNumber: number, pageSize: number) => void;
  handleSortChange: (sortParams: SortParams) => void;
  handleFilterChange: (filterParams: FilterParams) => void;
};

const Servers = ({
  pagedServers,
  onServerClicked,
  pagingParams,
  handlePageChange,
  handleSortChange,
  handleFilterChange,
}: PropTypes): JSX.Element => {
  return (
    <ServersTable
      pagedServers={pagedServers}
      onServerClicked={onServerClicked}
      pagingParams={pagingParams}
      handlePageChange={handlePageChange}
      handleSortChange={handleSortChange}
      handleFilterChange={handleFilterChange}
    />
  );
};

export default Servers;
