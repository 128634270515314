import { useState } from "react";
import { ButtonGroup } from "@mui/material";
import BrinkTypography from "../../shared/brinkTypography";
import CopyToClipboardButton from "../../shared/copyToClipboardButton";
import SearchableDataTable from "../../shared/searchableTable";
import {
  FilterParams,
  Group,
  PagingParams,
  Server,
  SortParams,
  TableHeaderParams,
} from "../../types";
import { RefEntity, buildUrl } from "../../shared/urlBuilder/adminPortal";

type GroupsData = {
  groups: Group[];
  totalCount: number;
};

type PropTypes = {
  groupsData: GroupsData;
  pagingParams: PagingParams;
  onGroupClicked: (selected: Group) => void;
  onServerClicked: (selected: Server) => void;
  handlePageChange: (pageNumber: number, pageSize: number) => void;
  handleSortChange: (sortParams: SortParams) => void;
  handleFilterChange: (filterParams: FilterParams) => void;
};

const tableHeaders: TableHeaderParams[] = [
  { title: "Groups", sortable: true, filterable: true, field: "name" },
  { title: "Locations", sortable: true, field: "locationsCount" },
  { title: "Group ID", sortable: true, field: "id" },
  { title: "Support", sortable: true, field: "supportType" },
  { title: "Stack", sortable: true, field: "server.alias" },
  // { title: "Online Terminals", sortable: false, field: "onlineTerminalsCount" },
];

const tableColumns = [
  "displayName",
  "locationsCount",
  "id",
  "supportType",
  "serverName",
  // "onlineTerminalsCount",
];

const GroupsTable = ({
  groupsData,
  pagingParams,
  onGroupClicked,
  onServerClicked,
  handlePageChange,
  handleSortChange,
  handleFilterChange,
}: PropTypes): JSX.Element => {
  const selectGroup = (selected: Group) => {
    onGroupClicked(selected);
  };

  const selectServer = (selected: Server) => {
    onServerClicked(selected);
  };

  const changeSearchQuery = (filterParams: FilterParams) => {
    handleFilterChange(filterParams);
  };

  const { groups, totalCount } = groupsData;

  const [hoveringGroupId, setHoveringGroupId] = useState(null);

  const handleMouseOver = (groupId: string) => {
    setHoveringGroupId(groupId);
  };

  const handleMouseOut = () => {
    setHoveringGroupId(null);
  };

  // Changing DisplayName and ServerName into React components for styling
  const groupsRows = groups.map((group) => ({
    ...group,
    displayName: () => (
      <span data-testid={`group-link-${group.id}`} role="button" tabIndex={0}>
        <a
          href={buildUrl(RefEntity.groups, group.server.name, group.id)}
          target="_blank"
          rel="noreferrer"
        >
          {group.name}
        </a>
      </span>
    ),
    locationsCount: () => (
      <span
        onClick={() => selectGroup(group)}
        data-testid={`selected-group-${group.id}`}
        role="button"
        tabIndex={0}
      >
        <BrinkTypography clickable>{group.locationsCount}</BrinkTypography>
      </span>
    ),
    id: () => (
      <span
        onMouseOver={() => handleMouseOver(group.id)}
        onFocus={() => handleMouseOver(group.id)}
        onMouseOut={() => handleMouseOut()}
        onBlur={() => handleMouseOut()}
      >
        <ButtonGroup variant="contained">
          <BrinkTypography>{group.id}</BrinkTypography>
          <CopyToClipboardButton
            text={group.name}
            show={hoveringGroupId === group.id}
          />
        </ButtonGroup>
      </span>
    ),
    serverName: () => (
      <span
        onClick={() => selectServer(group.server)}
        data-testid={`selected-server-${group.server.id}`}
        role="button"
        tabIndex={0}
      >
        <BrinkTypography clickable>{group.server.name}</BrinkTypography>
      </span>
    ),
  }));

  return (
    <SearchableDataTable
      rows={groupsRows}
      tableHeaders={tableHeaders}
      pagingParams={pagingParams}
      tableColumns={tableColumns}
      tableLength={totalCount}
      changePagination={handlePageChange}
      changeSort={handleSortChange}
      changeSearchQuery={changeSearchQuery}
    />
  );
};

export default GroupsTable;
