export interface UniqueEntity {
  id: string;
  name: string;
}

export type Summary = {
  serversCount: number;
  groupsCount: number;
  locationsCount: number;
  adminGroupsCount: number;
  adminUsersCount: number;
  integrationsCount: number;
};

export interface Terminal extends UniqueEntity {
  isOnline: boolean;
}

export interface Location extends UniqueEntity {
  businessDate: Date;
  dbName: string;
  onlineTerminalsCount: number;
  terminals: Terminal[];
  serverName: string;
  groupId: string;
}

export type Setting = {
  id: string;
  name: string;
};

export type Integration = UniqueEntity;

export type Configuration = {
  apiToken: string;
  encodeOrderIds: boolean;
  isFranchised: boolean;
  isLoyaltyEnabled: boolean;
};

export type SupportType = "Channel" | "P2P" | "Direct";

// Restaurant Group
export interface Group extends UniqueEntity {
  locationsCount: number;
  onlineTerminalsCount: number;
  server: Server;
  supportType: SupportType;
  locations: Location[];
  settings: Setting[];
  integrations: Integration[];
  contact?: Contact;
  address?: Address;
  supportingAdminGroup?: AdminGroup;
  configuration?: Configuration;
  notificationRecipients: AdminUser[];
}

export type ServerStatus = "Good" | "Bad";
export type Environment = "Production" | "UAT" | "API" | "Support" | "QA";

export type GlobalSetting = UniqueEntity;
export type EventLog = {
  id: string;
  message: string;
  recordedAt: Date;
};

export interface Server extends UniqueEntity {
  status: ServerStatus;
  environment: Environment;
  version: string;
  alias: string;
  bms: string;
  groupsCount: number;
  locationsCount: number;
  usersCount: number;
  globalSettings: GlobalSetting[];
}

export type Access = {
  adminUsers: AdminUser[];
  adminGroups: AdminGroup[];
};

export type ServerAccess = Access;
export type GroupAccess = Access;

export type Address = {
  id: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
};

export type Contact = {
  id: string;
  email: string;
  name: string;
  phone: string;
};

export interface UserRole extends UniqueEntity {
  isActive: boolean;
  groups?: Group[]; // subset of groups in the UserGroup
  adminUsers?: AdminUser[];
  usersCount?: number;
}

export interface AdminUser extends UniqueEntity {
  email: string;
  phone: string;
  role?: UserRole;
  groupsCount?: number;
  serversCount?: number;
  adminGroup?: AdminGroup;
  isActive: boolean;
}

export interface AdminGroup extends UniqueEntity {
  adminUsersCount: number;
  groupsCount: number;
  locationsCount: number;
  contact?: Contact;
  address?: Address;
  groups?: Group[];
  adminUsers?: AdminUser[];
  roles?: UserRole[];
  server?: Server;
  lastModifiedAt: Date | string;
}

export enum QueryKeys {
  GetAccess = "getAccess",
  GetAdminGroup = "getAdminGroup",
  GetAdminGroupGroups = "getAdminGroupGroups",
  GetAdminGroupRoles = "getAdminGroupRoles",
  GetAdminGroupUsers = "getAdminGroupUsers",
  GetGlobalSettings = "getGlobalSettings",
  GetGroup = "getGroup",
  GetGroupAccess = "getGroupAccess",
  GetGroupLocations = "getGroupLocations",
  GetGroups = "getGroups",
  GetSummary = "getSummary",
  GetServer = "getServer",
  GetIntegrations = "getIntegrations",
  GetServerAccess = "getServerAccess",
  GetServerEventLogs = "getServerEventLogs",
  GetServerGroups = "getServerGroups",
  GetServers = "getServers",
}

export type PagedServers = {
  servers: Server[];
  totalCount: number;
};

export type PagedGroups = {
  groups: Group[];
  totalCount: number;
};

export type PagedLocations = {
  locations: Location[];
  totalCount: number;
};

export type PagingParams = {
  page: number;
  size: number;
  sortParams?: SortParams;
  filterParams?: FilterParams;
};

export type SortParams = {
  sortField?: string;
  sortOrder?: "asc" | "desc";
};

export type FilterParams = {
  filterQuery?: string;
  filterColumn?: string;
};

export type TableHeaderParams = {
  sortable?: boolean;
  filterable?: boolean;
  title: string;
  field: string;
};
