import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import DataLoadingContainer from "../../../shared/dataLoadingContainer";
import { QueryKeys, Server } from "../../../types";
import ServerHeader from "./ServerHeader";

type PropTypes = {
  getServer: (serverId: string) => Promise<Server>;
};

type ServerParams = {
  serverId: string;
};

const ServerHeaderContainer = ({ getServer }: PropTypes): JSX.Element => {
  const { serverId } = useParams<ServerParams>();
  const queryFn = () => getServer(serverId);
  const queryKeys = [QueryKeys.GetServer, serverId];

  const history = useHistory();
  const { url } = useRouteMatch();

  const onClickTab = (path: string) => {
    history.push(`${url}/${path}`);
  };

  return (
    <DataLoadingContainer
      queryFn={queryFn}
      queryKeys={queryKeys}
      render={({ alias }: Server) => (
        <ServerHeader name={alias} onClickTab={onClickTab} />
      )}
    />
  );
};

export default ServerHeaderContainer;
