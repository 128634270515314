import Button from "@mui/material/Button";

export type BrinkButtonProps = {
  dataTestId?: string;
  customizedKey?: string;
  onClick?: () => void;
  disabled?: boolean;
  children: React.ReactNode;
  color?: "primary" | "secondary";
  className?: string;
  type?: "button" | "submit" | "reset";
};

const BrinkButton = ({
  dataTestId = null,
  customizedKey = null,
  disabled = false,
  onClick = () => null,
  children,
  color = "primary",
  className = null,
  type = "button",
}: BrinkButtonProps): JSX.Element => {
  return (
    <Button
      type={type}
      className={className}
      color={color}
      variant="outlined"
      data-testid={dataTestId}
      key={customizedKey}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default BrinkButton;
