import { Route, Switch, useRouteMatch } from "react-router";
import {
  AdminGroup,
  AdminUser,
  Group,
  PagedGroups,
  UserRole,
} from "../../types";
import Details from "./details";
import Groups from "./groups";
import Roles from "./roles";
import Users from "./users";

type PropTypes = {
  adminGroup: AdminGroup;
  getAdminGroupGroups: (adminGroupId: string) => Promise<PagedGroups>;
  getAdminGroupUsers: (adminGroupId: string) => Promise<AdminUser[]>;
  getAdminGroupRoles: (adminGroupId: string) => Promise<UserRole[]>;
};

const AdminGroupComponent = ({
  adminGroup,
  getAdminGroupGroups,
  getAdminGroupUsers,
  getAdminGroupRoles,
}: PropTypes): JSX.Element => {
  const { path } = useRouteMatch();
  const { id } = adminGroup;

  return (
    <Switch>
      <Route path={`${path}/details`}>
        <Details adminGroup={adminGroup} />
      </Route>
      <Route path={`${path}/users`}>
        <Users adminGroupId={id} getAdminGroupUsers={getAdminGroupUsers} />
      </Route>
      <Route path={`${path}/roles`}>
        <Roles adminGroupId={id} getAdminGroupRoles={getAdminGroupRoles} />
      </Route>
      <Route path={`${path}/groups`}>
        <Groups adminGroupId={id} getAdminGroupGroups={getAdminGroupGroups} />
      </Route>
      <Route path={`${path}`}>
        <Groups adminGroupId={id} getAdminGroupGroups={getAdminGroupGroups} />
      </Route>
    </Switch>
  );
};

export default AdminGroupComponent;
