import { Switch, Route, useRouteMatch } from "react-router";
import { Group, GroupAccess, PagedLocations, PagingParams } from "../../types";
import Locations from "./locations";
import Access from "./access";
import Settings from "./Settings";
import Integrations from "./Integrations";
import Info from "./info";

type PropTypes = {
  group: Group;
  getGroupAccess: (groupId: string) => Promise<GroupAccess>;
  getGroupLocations: (
    serverId: string,
    pagingParams: PagingParams
  ) => Promise<PagedLocations>;
};

const GroupComponent = ({
  group,
  getGroupAccess,
  getGroupLocations,
}: PropTypes): JSX.Element => {
  const { path } = useRouteMatch();

  const { id, settings, integrations } = group;

  return (
    <Switch>
      <Route path={`${path}/info`}>
        <Info group={group} />
      </Route>
      <Route path={`${path}/integrations`}>
        <Integrations integrations={integrations} />
      </Route>
      <Route path={`${path}/settings`}>
        <Settings settings={settings} />
      </Route>
      <Route path={`${path}/access`}>
        <Access groupId={id} getGroupAccess={getGroupAccess} />
      </Route>
      <Route path={`${path}/groups`}>
        <Locations groupId={id} getGroupLocations={getGroupLocations} />
      </Route>
      <Route path={`${path}`}>
        <Locations groupId={id} getGroupLocations={getGroupLocations} />
      </Route>
    </Switch>
  );
};

export default GroupComponent;
