import { useState } from "react";
import DataLoadingContainer from "../../../shared/dataLoadingContainer";
import {
  Location,
  PagedLocations,
  QueryKeys,
  SortParams,
  PagingParams,
  FilterParams,
} from "../../../types";
import Locations from "./Locations";

type PropTypes = {
  groupId: string;
  getGroupLocations: (
    groupId: string,
    pagingParams: PagingParams
  ) => Promise<PagedLocations>;
};
const LocationsContainer = ({
  groupId,
  getGroupLocations,
}: PropTypes): JSX.Element => {
  const [pagingParams, setPagingParams] = useState<PagingParams>({
    page: 0,
    size: 10,
    filterParams: { filterQuery: null, filterColumn: "name" },
    sortParams: { sortOrder: "asc", sortField: null },
  });

  const handlePageChange = (newPage: number, newSize: number) => {
    setPagingParams({ ...pagingParams, page: newPage, size: newSize });
  };

  const handleSortChange = (sortParams: SortParams) => {
    setPagingParams({ ...pagingParams, sortParams });
  };

  const handleFilterChange = (filterParams: FilterParams) => {
    setPagingParams({ ...pagingParams, filterParams, page: 0 });
  };

  const queryKeys = [
    QueryKeys.GetGroupLocations,
    groupId,
    pagingParams.page,
    pagingParams.size,
    pagingParams.sortParams.sortField,
    pagingParams.sortParams.sortOrder,
    pagingParams.filterParams.filterColumn,
    pagingParams.filterParams.filterQuery,
  ];

  const queryFn = () => getGroupLocations(groupId, pagingParams);

  return (
    <DataLoadingContainer
      queryKeys={queryKeys}
      queryFn={queryFn}
      render={(data: PagedLocations) => {
        return (
          <Locations
            pagedLocations={data}
            pagingParams={pagingParams}
            handlePageChange={handlePageChange}
            handleSortChange={handleSortChange}
            handleFilterChange={handleFilterChange}
          />
        );
      }}
    />
  );
};

export default LocationsContainer;
