import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import DataLoadingContainer from "../../../shared/dataLoadingContainer";
import { AdminGroup, QueryKeys } from "../../../types";
import AdminGroupHeader from "./AdminGroupHeader";

type PropTypes = {
  getAdminGroup: (adminGroupId: string) => Promise<AdminGroup>;
};

type ServerParams = {
  adminGroupId: string;
};

const AdminGroupHeaderContainer = ({
  getAdminGroup,
}: PropTypes): JSX.Element => {
  const { adminGroupId } = useParams<ServerParams>();
  const queryFn = () => getAdminGroup(adminGroupId);
  const queryKeys = [QueryKeys.GetAdminGroup, adminGroupId];

  const history = useHistory();
  const { url } = useRouteMatch();

  const onClickTab = (path: string) => {
    history.push(`${url}/${path}`);
  };

  return (
    <DataLoadingContainer
      queryFn={queryFn}
      queryKeys={queryKeys}
      render={({ name }: AdminGroup) => (
        <AdminGroupHeader name={name} onClickTab={onClickTab} />
      )}
    />
  );
};

export default AdminGroupHeaderContainer;
