import { useState } from "react";
import { useHistory } from "react-router-dom";
import DataLoadingContainer from "../../shared/dataLoadingContainer";
import {
  Server,
  QueryKeys,
  PagingParams,
  SortParams,
  FilterParams,
} from "../../types";
import Servers from "./Servers";

type PagedServers = {
  servers: Server[];
  totalCount: number;
};

type PropTypes = {
  getServers: (pagingParams: PagingParams) => Promise<PagedServers>;
};

const ServersContainer = ({ getServers }: PropTypes): JSX.Element => {
  const [paginationParams, setPaginationParams] = useState<PagingParams>({
    page: 0,
    size: 10,
    filterParams: { filterQuery: null, filterColumn: "Name" },
    sortParams: { sortOrder: "asc", sortField: null },
  });

  const history = useHistory();

  const onServerClicked = (server: Server) => {
    history.push(`/servers/${server.id}`);
  };

  const handlePageChange = (newPage: number, newSize: number) => {
    setPaginationParams({ ...paginationParams, page: newPage, size: newSize });
  };

  const handleSortChange = (sortParams: SortParams) => {
    setPaginationParams({ ...paginationParams, sortParams });
  };

  const handleFilterChange = (filterParams: FilterParams) => {
    setPaginationParams({ ...paginationParams, filterParams, page: 0 });
  };

  const queryKeys = [
    QueryKeys.GetServers,
    paginationParams.page,
    paginationParams.size,
    paginationParams.sortParams.sortOrder,
    paginationParams.sortParams.sortField,
    paginationParams.filterParams.filterQuery,
    paginationParams.filterParams.filterColumn,
  ];

  const queryFn = () => getServers(paginationParams);

  return (
    <DataLoadingContainer
      queryFn={queryFn}
      queryKeys={queryKeys}
      render={(data: PagedServers) => (
        <Servers
          pagedServers={data}
          onServerClicked={onServerClicked}
          pagingParams={paginationParams}
          handlePageChange={handlePageChange}
          handleSortChange={handleSortChange}
          handleFilterChange={handleFilterChange}
        />
      )}
    />
  );
};

export default ServersContainer;
