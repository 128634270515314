import { Integration } from "../../types";

type PropTypes = {
  integrations: Integration[];
};

const Integrations = ({ integrations }: PropTypes): JSX.Element => {
  return <>{JSON.stringify(integrations)}</>;
};

export default Integrations;
