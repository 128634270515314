import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    endIcon: {
      top: "0px",
      left: "7px",
      position: "absolute",
      width: "5px",
    },
    startIcon: {
      marginRight: "0px",
      position: "absolute",
    },
    container: {
      position: "relative",
      boxShadow: "none",
      "&:hover": {
        background: "none",
      },
    },
  })
);

export default useStyles;
