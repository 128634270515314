import BrinkTypography from "../brinkTypography";
import "./title.css";

type TitleProps = {
  title: string;
};

const Title = ({ title }: TitleProps): JSX.Element => (
  <BrinkTypography
    variant="h3"
    color="secondary"
    component="div"
    data-testid="title"
  >
    {title}
  </BrinkTypography>
);

export default Title;
