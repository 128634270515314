import DataLoadingContainer from "../../../shared/dataLoadingContainer";
import { EventLog, QueryKeys } from "../../../types";
import EventLogs from "./EventLogs";

type PropTypes = {
  serverId: string;
  getServerEventLogs: (serverId: string) => Promise<EventLog[]>;
};
const EventLogsContainer = ({
  serverId,
  getServerEventLogs,
}: PropTypes): JSX.Element => {
  const queryKeys = [QueryKeys.GetServerEventLogs, serverId];
  const queryFn = () => getServerEventLogs(serverId);

  return (
    <DataLoadingContainer
      queryKeys={queryKeys}
      queryFn={queryFn}
      render={(data: EventLog[]) => {
        return <EventLogs eventLogs={data} />;
      }}
    />
  );
};

export default EventLogsContainer;
