import {
  FilterParams,
  Group,
  PagingParams,
  Server,
  SortParams,
} from "../../types";
import GroupsTable from "./GroupsTable";

type GroupsData = {
  groups: Group[];
  totalCount: number;
};

type PropTypes = {
  groupsData: GroupsData;
  pagingParams?: PagingParams;
  onGroupClicked: (selected: Group) => void;
  onServerClicked: (selected: Server) => void;
  handlePageChange: (pageNumber: number, pageSize: number) => void;
  handleSortChange?: (sortParams: SortParams) => void;
  handleFilterChange?: (filterParams: FilterParams) => void;
};

const Groups = ({
  groupsData,
  pagingParams,
  onGroupClicked,
  onServerClicked,
  handlePageChange,
  handleSortChange,
  handleFilterChange,
}: PropTypes): JSX.Element => {
  return (
    <GroupsTable
      groupsData={groupsData}
      pagingParams={pagingParams}
      onGroupClicked={onGroupClicked}
      onServerClicked={onServerClicked}
      handlePageChange={handlePageChange}
      handleSortChange={handleSortChange}
      handleFilterChange={handleFilterChange}
    />
  );
};

export default Groups;
