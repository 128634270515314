import "./groupInfo.css";
import BrinkTable from "../../../shared/brinkTable";
import BrinkTypography from "../../../shared/brinkTypography";
import { AdminUser, TableHeaderParams } from "../../../types";

type PropTypes = {
  notificationRecipients: AdminUser[];
};

const tableColumns = ["name", "adminGroup.name", "role.name", "email"];
const tableHeaders: TableHeaderParams[] = [
  { title: "User", sortable: false, field: "name" },
  { title: "Group", sortable: false, field: "adminGroup.name" },
  { title: "Role", sortable: false, field: "role.name" },
  { title: "Email", sortable: false, field: "email" }
];


const NotificationRecipients = ({
  notificationRecipients,
}: PropTypes): JSX.Element => {
  return (
    <div className="info-container">
      <BrinkTypography variant="h6">
        <b>Notification Recipients</b>
      </BrinkTypography>
      <BrinkTable
        rows={notificationRecipients}
        tableColumns={tableColumns}
        tableHeaders={tableHeaders}
        renderAction={false}
      />
    </div>
  );
};

export default NotificationRecipients;
