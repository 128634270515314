import { Box } from "@mui/material";
import GlobalTopologyService from "../api/globalTopologyService";
import BodyContainer from "./body";
import HeaderContainer from "./header";

type PropTypes = {
  topologyClient: GlobalTopologyService;
};

const Views = ({ topologyClient }: PropTypes): JSX.Element => {
  return (
    <Box display="flex" flexDirection="column" marginRight={2}>
      <Box>
        <HeaderContainer topologyClient={topologyClient} />
        <BodyContainer topologyClient={topologyClient} />
      </Box>
    </Box>
  );
};

export default Views;
