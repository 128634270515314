import { useState } from "react";
import { ButtonGroup } from "@mui/material";
import {
  FilterParams,
  Group,
  PagingParams,
  Server,
  SortParams,
  TableHeaderParams,
} from "../../types";
import BrinkTypography from "../brinkTypography";
import SearchableDataTable from "../searchableTable";
import CopyToClipboardButton from "../copyToClipboardButton";
import { RefEntity, buildUrl } from "../urlBuilder/adminPortal";

type GroupsData = {
  totalCount: number;
  groups: Group[];
};

type PropTypes = {
  groupsData: GroupsData;
  pagingParams: PagingParams;
  onGroupClicked: (selected: Group) => void;
  onServerClicked: (selected: Server) => void;
  handlePageChange: (pageNumber: number, size: number) => void;
  handleSortChange: (sortParams: SortParams) => void;
  handleFilterChange: (filterParams: FilterParams) => void;
};

const tableHeaders: TableHeaderParams[] = [
  { title: "Groups", sortable: true, filterable: true, field: "name" },
  { title: "Locations", sortable: true, field: "locationsCount" },
  { title: "Group ID", sortable: true, field: "id" },
  // { title: "Online Terminals", sortable: true, field: "onlineTerminalsCount" },
  { title: "Stack", sortable: true, field: "serverName" },
];

const tableColumns = [
  "displayName",
  "locationsCount",
  "id",
  // "onlineTerminalsCount",
  "serverName",
];

const Groups = ({
  groupsData,
  pagingParams,
  onGroupClicked,
  onServerClicked,
  handlePageChange,
  handleSortChange,
  handleFilterChange,
}: PropTypes): JSX.Element => {
  const selectGroup = (selected: Group) => onGroupClicked(selected);
  const selectServer = (selected: Server) => onServerClicked(selected);

  const changeSearchQuery = (filterParams: FilterParams) => {
    handleFilterChange(filterParams);
  };

  const [hoveringGroupId, setHoveringGroupId] = useState(null);

  const handleMouseOver = (groupId: string) => {
    setHoveringGroupId(groupId);
  };

  const handleMouseOut = () => {
    setHoveringGroupId(null);
  };

  const { groups, totalCount } = groupsData;
  const groupsRows = groups.map((group) => ({
    ...group,
    displayName: () => {
      return (
        <span data-testid={`group-link-${group.id}`} role="button" tabIndex={0}>
          <a
            href={buildUrl(RefEntity.groups, group.server.name, group.id)}
            target="_blank"
            rel="noreferrer"
          >
            {group.name}
          </a>
        </span>
      );
    },
    id: () => (
      <span
        onMouseOver={() => handleMouseOver(group.id)}
        onFocus={() => handleMouseOver(group.id)}
        onMouseOut={() => handleMouseOut()}
        onBlur={() => handleMouseOut()}
      >
        <ButtonGroup variant="contained">
          <BrinkTypography>{group.id}</BrinkTypography>
          <CopyToClipboardButton
            text={group.name}
            show={hoveringGroupId === group.id}
          />
        </ButtonGroup>
      </span>
    ),
    serverName: () => (
      <span
        onClick={() => selectServer(group.server)}
        data-testid={`selected-server-${group.server.id}`}
        role="button"
        tabIndex={0}
      >
        <BrinkTypography clickable>{group.server.name}</BrinkTypography>
      </span>
    ),
    locationsCount: () => (
      <span
        onClick={() => selectGroup(group)}
        data-testid={`selected-group-${group.id}`}
        role="button"
        tabIndex={0}
      >
        <BrinkTypography clickable>{group.locationsCount}</BrinkTypography>
      </span>
    ),
  }));

  return (
    <SearchableDataTable
      rows={groupsRows}
      pagingParams={pagingParams}
      tableColumns={tableColumns}
      tableHeaders={tableHeaders}
      tableLength={totalCount}
      changePagination={handlePageChange}
      changeSort={handleSortChange}
      changeSearchQuery={changeSearchQuery}
    />
  );
};

export default Groups;
