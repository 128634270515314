import { GlobalSetting } from "../../types";

type PropTypes = {
  globalSettings: GlobalSetting[];
};

const GlobalSettings = ({ globalSettings }: PropTypes): JSX.Element => {
  return (
    <div>
      <p>Server - Global Settings</p>
      <p>{JSON.stringify(globalSettings)}</p>
    </div>
  );
};

export default GlobalSettings;
