import NameSearchableDataTable from "../../../shared/nameSearchableDataTable";
import { AdminUser, TableHeaderParams } from "../../../types";

type PropTypes = {
  users: AdminUser[];
};

const tableColumns = ["name", "role.name", "isActive"];
const tableHeaders: TableHeaderParams[] = [
  { title: "Users", sortable: false, field: "name" },
  { title: "Roles", sortable: false, field: "role.name" },
  { title: "Active", sortable: false, field: "isActive" }
];

const UsersTable = ({ users }: PropTypes): JSX.Element => {
  const usersTableData = users.map((user) => ({
    ...user,
    isActive: user.isActive === true ? "Yes" : "No",
  }));

  return (
    <NameSearchableDataTable
      rows={usersTableData}
      pagingParams={{
        page: 0,
        size: 10,
        filterParams: { filterQuery: null, filterColumn: "name" },
        sortParams: { sortOrder: "asc", sortField: null },
      }}
      tableHeaders={tableHeaders}
      tableColumns={tableColumns}
    />
  );
};

export default UsersTable;
