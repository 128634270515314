import NameSearchableDataTable from "../nameSearchableDataTable";
import { AdminGroup, Server, TableHeaderParams } from "../../types";
import { adminGroup } from "../testUtils/testingObjects";
import BrinkTypography from "../brinkTypography";

type PropTypes = {
  adminGroups: AdminGroup[];
  onAdminGroupSelected: (selected: AdminGroup) => void;
  onServerSelected: (selected: Server) => void;
};

const tableHeaders: TableHeaderParams[] = [
  { title: "Groups", field: "displayName" },
  { title: "Users", field: "adminUsersCount" },
  { title: "Concepts", field: "groupsCount" },
  { title: "Locations", field: "locationsCount" },
  { title: "Stacks", field: "serverName" },
  { title: "Modified", field: "lastModifiedAt" },
];

const tableColumns = [
  "displayName",
  "adminUsersCount",
  "groupsCount",
  "locationsCount",
  "serverName",
  "lastModifiedAt",
];

const UserGroupsTable = ({
  adminGroups,
  onAdminGroupSelected,
  onServerSelected,
}: PropTypes): JSX.Element => {
  const selectGroup = (selected: AdminGroup) => {
    onAdminGroupSelected(selected);
  };

  const selectServer = (selected: Server) => {
    onServerSelected(selected);
  };

  const adminGroupsData = adminGroups.map((currentAdminGroup) => ({
    ...adminGroup,
    displayName: () => (
      <span
        onClick={() => selectGroup(currentAdminGroup)}
        data-testid={`selected-group-${adminGroup.id}`}
        role="button"
        tabIndex={0}
      >
        <BrinkTypography clickable>{currentAdminGroup.name}</BrinkTypography>
      </span>
    ),
    serverName: () => (
      <span
        onClick={() => selectServer(currentAdminGroup.server)}
        data-testid={`selected-server-${currentAdminGroup.server.id}`}
        role="button"
        tabIndex={0}
      >
        <BrinkTypography clickable>
          {currentAdminGroup.server.alias}
        </BrinkTypography>
      </span>
    ),
  }));

  return (
    <NameSearchableDataTable
      rows={adminGroupsData}
      pagingParams={{
        page: 0,
        size: 10,
        filterParams: { filterQuery: null, filterColumn: "displayName" },
        sortParams: { sortOrder: "asc", sortField: null },
      }}
      tableHeaders={tableHeaders}
      tableColumns={tableColumns}
    />
  );
};

export default UserGroupsTable;
