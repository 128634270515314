import { useState } from "react";
import { useHistory } from "react-router-dom";
import DataLoadingContainer from "../../shared/dataLoadingContainer";
import {
  PagedGroups,
  Group,
  QueryKeys,
  Server,
  PagingParams,
  SortParams,
  FilterParams,
} from "../../types";
import Groups from "./Groups";

type PropTypes = {
  getGroups: (PagingParams) => Promise<PagedGroups>;
};

const GroupsContainer = ({ getGroups }: PropTypes): JSX.Element => {
  const [paginationParams, setPaginationParams] = useState<PagingParams>({
    page: 0,
    size: 10,
    filterParams: { filterQuery: null, filterColumn: "name" },
    sortParams: { sortOrder: "asc", sortField: null },
  });

  const history = useHistory();
  const onGroupClicked = (group: Group) => {
    history.push(`/groups/${group.id}`);
  };

  const onServerClicked = (server: Server) => {
    history.push(`/servers/${server.id}`);
  };

  const handlePageChange = (newPage: number, newSize: number) => {
    setPaginationParams({ ...paginationParams, page: newPage, size: newSize });
  };

  const handleSortChange = (sortParams: SortParams) => {
    setPaginationParams({ ...paginationParams, sortParams });
  };

  const handleFilterChange = (filterParams: FilterParams) => {
    setPaginationParams({ ...paginationParams, filterParams, page: 0 });
  };

  const queryFn = () => getGroups(paginationParams);

  const queryKeys = [
    QueryKeys.GetGroups,
    paginationParams.page,
    paginationParams.size,
    paginationParams.sortParams.sortOrder,
    paginationParams.sortParams.sortField,
    paginationParams.filterParams.filterQuery,
    paginationParams.filterParams.filterColumn,
  ];

  return (
    <DataLoadingContainer
      queryFn={queryFn}
      queryKeys={queryKeys}
      render={(data: PagedGroups) => (
        <Groups
          groupsData={data}
          pagingParams={paginationParams}
          onGroupClicked={onGroupClicked}
          onServerClicked={onServerClicked}
          handlePageChange={handlePageChange}
          handleSortChange={handleSortChange}
          handleFilterChange={handleFilterChange}
        />
      )}
    />
  );
};

export default GroupsContainer;
