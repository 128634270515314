import { Box } from "@mui/material";
import DataLoadingContainer from "../../shared/dataLoadingContainer";
import { Integration, QueryKeys } from "../../types";
import Integrations from "./Integrations";

type PropTypes = {
  getIntegrations: () => Promise<Integration[]>;
};

const IntegrationsContainer = ({ getIntegrations }: PropTypes): JSX.Element => {
  const queryKeys = [QueryKeys.GetIntegrations];
  const queryFn = getIntegrations;

  return (
    <DataLoadingContainer
      queryKeys={queryKeys}
      queryFn={queryFn}
      render={(data: Integration[]) => (
        <Box paddingTop={2}>
          <Integrations integrations={data} />
        </Box>
      )}
    />
  );
};

export default IntegrationsContainer;
