import { Switch, Route, useRouteMatch } from "react-router";
import {
  Server,
  ServerAccess,
  PagedGroups,
  EventLog,
  PagingParams,
} from "../../types";
import Groups from "./groups";
import Access from "./access";
import GlobalSettings from "./GlobalSettings";
import EventLogs from "./eventLogs";

type PropTypes = {
  server: Server;
  getServerAccess: (serverId: string) => Promise<ServerAccess>;
  getServerEventLogs: (serverId: string) => Promise<EventLog[]>;
  getServerGroups: (
    serverId: string,
    pagingParams: PagingParams
  ) => Promise<PagedGroups>;
};

const ServerComponent = ({
  server,
  getServerAccess,
  getServerGroups,
  getServerEventLogs,
}: PropTypes): JSX.Element => {
  const { path } = useRouteMatch();

  const { id, globalSettings } = server;

  return (
    <Switch>
      <Route path={`${path}/access`}>
        <Access serverId={id} getServerAccess={getServerAccess} />
      </Route>
      <Route path={`${path}/globalSettings`}>
        <GlobalSettings globalSettings={globalSettings} />
      </Route>
      <Route path={`${path}/eventLog`}>
        <EventLogs serverId={id} getServerEventLogs={getServerEventLogs} />
      </Route>
      <Route path={`${path}/groups`}>
        <Groups serverId={id} getServerGroups={getServerGroups} />
      </Route>
      <Route path={`${path}`}>
        <Groups serverId={id} getServerGroups={getServerGroups} />
      </Route>
    </Switch>
  );
};

export default ServerComponent;
