import { Integration } from "../../types";

type PropTypes = {
  integrations: Integration[];
};

const Integrations = ({ integrations }: PropTypes): JSX.Element => {
  return (
    <div>
      <p>Group - Integrations</p>
      <p>{JSON.stringify(integrations)}</p>
    </div>
  );
};

export default Integrations;
