import { v4 } from "uuid";
import { isFunction, get } from "lodash";
import { styled } from "@mui/material";
import BrinkTableBody from "../../brinkTable/BrinkTableBody";
import BrinkTableRow from "../../brinkTable/BrinkTableRow";
import BrinkTableCell from "../../brinkTable/BrinkTableCell";

import "./tableRows.css";
import BrinkTypography from "../../brinkTypography";

const StyledTableRow = styled(BrinkTableRow)(({ theme }) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const isDate = (date: any) =>
  Object.prototype.toString.call(date) === "[object Date]";

type TableRowsProps = {
  page: number;
  rowsPerPage: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rows: any;
  columns: string[];
  renderAction?: boolean;
};

function TableRows({
  page,
  rowsPerPage,
  rows,
  columns,
  renderAction = true,
}: TableRowsProps): JSX.Element {
  const newRows = rows ?? [];
  return (
    <BrinkTableBody>
      {newRows
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((row: any) => (
          <StyledTableRow hover role="row" tabIndex={-1} key={row.id}>
            {columns.map((column) => {
              const cellValue = get(row, column, "");

              return (
                <BrinkTableCell key={column}>
                  {isFunction(cellValue) ? (
                    cellValue()
                  ) : (
                    <BrinkTypography>
                      {isDate(cellValue)
                        ? cellValue.toLocaleString()
                        : cellValue}
                    </BrinkTypography>
                  )}
                </BrinkTableCell>
              );
            })}
            {/* {renderAction && (
              <BrinkTableCell key={v4()}>
                <BrinkTypography>...</BrinkTypography>
              </BrinkTableCell>
            )} */}
          </StyledTableRow>
        ))}
    </BrinkTableBody>
  );
}

export default TableRows;
