import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { useCookies } from "react-cookie";
import { ThemeProvider } from "@mui/material";
import Views from "./views";
import GlobalTopologyService from "./api/globalTopologyService";
import theme from "./theme";

// We are ts ignoring this because its shared by the container app
// @ts-ignore
import { AuthTokenManager } from "@par/support-portal-auth";

const queryClient = new QueryClient();
const App = (): JSX.Element => {
  const [cookies] = useCookies(["name", "username", "topologyAccessToken"]);

  if (!cookies.username) {
    window.location.href = "/?returnUrl=/topology";

    return <></>;
  }

  const baseUrl = process.env.TOPOLOGY_SERVICE_BASEURL;
  const topologyClient = new GlobalTopologyService({
    baseUrl,
    getAccessTokenAsync: () => AuthTokenManager.getTokenAsync(),
  });

  // TODO: Redirect to root if cookie does not contain username
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Router basename="/topology">
          <Views topologyClient={topologyClient} />
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
