import { useHistory } from "react-router-dom";
import DataLoadingContainer from "../../../shared/dataLoadingContainer";
import { QueryKeys, Summary } from "../../../types";
import RootHeader from "./RootHeader";

type PropTypes = {
  getSummary: () => Promise<Summary>;
};

const RootHeaderContainer = ({ getSummary }: PropTypes): JSX.Element => {
  const queryFn = getSummary;
  const queryKeys = [QueryKeys.GetSummary];

  const history = useHistory();
  const onClickTab = (path: string) => {
    history.push(`${path}`);
  };

  return (
    <DataLoadingContainer
      queryFn={queryFn}
      queryKeys={queryKeys}
      render={(data: Summary) => (
        <RootHeader summary={data} onClickTab={onClickTab} />
      )}
    />
  );
};

export default RootHeaderContainer;
