import BrinkTypography from "../brinkTypography";
import { Address, Contact } from "../../types";
import "./groupInfo.css";

type PropTypes = {
  id: string;
  name: string;
  address: Address;
  contact: Contact;
};

const GroupInfo = ({ id, name, address, contact }: PropTypes): JSX.Element => {
  return (
    <>
      <div className="info-row">
        <div className="info-title text-color">
          <BrinkTypography>Group Name: </BrinkTypography>
        </div>

        <div className="info-value">
          <BrinkTypography>{name}</BrinkTypography>
        </div>

        <div className="info-title text-color">
          <BrinkTypography>Address 1: </BrinkTypography>
        </div>

        <div className="info-value">
          <BrinkTypography>{address?.address1}</BrinkTypography>
        </div>
      </div>

      <div className="info-row">
        <div className="info-title text-color">
          <BrinkTypography>Group ID: </BrinkTypography>
        </div>

        <div className="info-value">
          <BrinkTypography>{id}</BrinkTypography>
        </div>

        <div className="info-title text-color">
          <BrinkTypography>Address 2: </BrinkTypography>
        </div>

        <div className="info-value">
          <BrinkTypography>{address?.address2}</BrinkTypography>
        </div>
      </div>

      <div className="info-row">
        <div className="info-title text-color">
          <BrinkTypography>Contact: </BrinkTypography>
        </div>

        <div className="info-value">
          <BrinkTypography>{contact?.name}</BrinkTypography>
        </div>

        <div className="info-title text-color">
          <BrinkTypography>City: </BrinkTypography>
        </div>

        <div className="info-value">
          <BrinkTypography>{address?.city}</BrinkTypography>
        </div>
      </div>

      <div className="info-row">
        <div className="info-title text-color">
          <BrinkTypography>Contact Email: </BrinkTypography>
        </div>

        <div className="info-value">
          <BrinkTypography>{contact?.email}</BrinkTypography>
        </div>

        <div className="info-title text-color">
          <BrinkTypography>State: </BrinkTypography>
        </div>

        <div className="info-value">
          <BrinkTypography>{address?.state}</BrinkTypography>
        </div>
      </div>

      <div className="info-row">
        <div className="info-title text-color">
          <BrinkTypography>Contact Phone: </BrinkTypography>
        </div>

        <div className="info-value">
          <BrinkTypography>{contact?.phone}</BrinkTypography>
        </div>

        <div className="info-title text-color">
          <BrinkTypography>Zip: </BrinkTypography>
        </div>

        <div className="info-value">
          <BrinkTypography>{address?.zip}</BrinkTypography>
        </div>
      </div>
    </>
  );
};

export default GroupInfo;
