import { useState } from "react";
import { AdminUser, AdminGroup, Server } from "../../types";
import AccessButtonGroup from "./AccessButtonGroup";
import UserGroupsTable from "./UserGroupsTable";
import UsersTable from "./UsersTable";

type PropTypes = {
  adminGroups: AdminGroup[];
  adminUsers: AdminUser[];
  onAdminGroupSelected: (selected: AdminGroup) => void;
  onServerSelected: (selected: Server) => void;
};

const Access = ({
  adminGroups,
  adminUsers,
  onAdminGroupSelected,
  onServerSelected,
}: PropTypes): JSX.Element => {
  const [currentGroupState, setCurrentGroupState] = useState("Admin Groups");
  const showAdminUsers = currentGroupState === "Admin Users";
  const selectGroup = (group: string) => {
    if (group !== currentGroupState) {
      setCurrentGroupState(group);
    }
  };

  return (
    <>
      <AccessButtonGroup
        currentGroup={currentGroupState}
        selectGroup={selectGroup}
      />
      {showAdminUsers ? (
        <UsersTable adminUsers={adminUsers} />
      ) : (
        <UserGroupsTable
          adminGroups={adminGroups}
          onAdminGroupSelected={onAdminGroupSelected}
          onServerSelected={onServerSelected}
        />
      )}
    </>
  );
};

export default Access;
