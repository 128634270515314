import { CookiesProvider } from "react-cookie";
import App from "./App";

const Topology = (): JSX.Element => (
  <CookiesProvider>
    <App />
  </CookiesProvider>
);

export default Topology;
