import "./summaryStats.css";
import Stat from "./stat/Stat";
import { Summary } from "../../../../types";

export type PropTypes = {
  summary: Summary;
};

const SummaryStats = ({ summary }: PropTypes): JSX.Element => {
  const {
    serversCount,
    groupsCount,
    locationsCount,
    adminGroupsCount,
    adminUsersCount,
    integrationsCount,
  } = summary;
  return (
    <div className="summary-container">
      <Stat title="Stacks" value={serversCount} />
      <Stat title="Groups" value={groupsCount} />
      <Stat title="Locations" value={locationsCount} />
      <Stat title="Admin Groups" value={adminGroupsCount} />
      <Stat title="Admin Users" value={adminUsersCount} />
      <Stat title="API Integrators" value={integrationsCount} />
    </div>
  );
};

export default SummaryStats;
