import NameSearchableDataTable from "../nameSearchableDataTable";
import { AdminUser, TableHeaderParams } from "../../types";

type PropTypes = {
  adminUsers: AdminUser[];
};

const tableColumns = [
  "name",
  "adminGroup.name",
  "groupsCount",
  "serversCount",
  "role.name",
];

const tableHeaders: TableHeaderParams[] = [
  { title: "User", sortable: false, field: "name" },
  { title: "Admin Group", sortable: false, field: "adminGroup.name" },
  { title: "Concepts", sortable: false, field: "groupsCount" },
  { title: "Servers", sortable: false, field: "serversCount" },
  { title: "Roles", sortable: false, field: "role.name" },
];

const UsersTable = ({ adminUsers }: PropTypes): JSX.Element => {
  return (
    <NameSearchableDataTable
      rows={adminUsers}
      pagingParams={{
        page: 0,
        size: 10,
        filterParams: { filterQuery: null, filterColumn: "name" },
        sortParams: { sortOrder: "asc", sortField: null },
      }}
      tableHeaders={tableHeaders}
      tableColumns={tableColumns}
    />
  );
};

export default UsersTable;
