import { Switch, Route } from "react-router-dom";
import GlobalTopologyService from "../../api/globalTopologyService";
import AdminGroupHeader from "./adminGroupHeader";
import GroupHeader from "./groupHeader";
import RootHeader from "./rootHeader";
import ServerHeader from "./serverHeader";

type PropTypes = {
  topologyClient: GlobalTopologyService;
};

const HeaderContainer = ({ topologyClient }: PropTypes): JSX.Element => {
  return (
    <Switch>
      <Route path="/servers/:serverId">
        <ServerHeader getServer={topologyClient.getServer} />
      </Route>
      <Route path="/groups/:groupId">
        <GroupHeader getGroup={topologyClient.getGroup} />
      </Route>
      <Route path="/adminGroups/:adminGroupId">
        <AdminGroupHeader getAdminGroup={topologyClient.getAdminGroup} />
      </Route>
      <Route>
        <RootHeader getSummary={topologyClient.getSummary} />
      </Route>
    </Switch>
  );
};

export default HeaderContainer;
