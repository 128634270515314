import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import DataLoadingContainer from "../../../shared/dataLoadingContainer";
import { Group, QueryKeys } from "../../../types";
import GroupHeader from "./GroupHeader";

type PropTypes = {
  getGroup: (groupId: string) => Promise<Group>;
};

type ServerParams = {
  groupId: string;
};

const GroupHeaderContainer = ({ getGroup }: PropTypes): JSX.Element => {
  const { groupId } = useParams<ServerParams>();
  const queryFn = () => getGroup(groupId);
  const queryKeys = [QueryKeys.GetGroup, groupId];

  const history = useHistory();
  const { url } = useRouteMatch();

  const onClickTab = (path: string) => {
    history.push(`${url}/${path}`);
  };

  return (
    <DataLoadingContainer
      queryFn={queryFn}
      queryKeys={queryKeys}
      render={({ name }: Group) => (
        <GroupHeader name={name} onClickTab={onClickTab} />
      )}
    />
  );
};

export default GroupHeaderContainer;
