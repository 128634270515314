import NameSearchableDataTable from "../../../shared/nameSearchableDataTable";
import { TableHeaderParams, UserRole } from "../../../types";

type PropTypes = {
  roles: UserRole[];
};

const tableColumns = ["name", "usersCount", "isActive"];
const tableHeaders: TableHeaderParams[] = [
  { title: "Roles", sortable: false, field: "name" },
  { title: "Users", sortable: false, field: "usersCount" },
  { title: "Active", sortable: false, field: "isActive" },
];

const RolesTable = ({ roles }: PropTypes): JSX.Element => {
  const rolesTableData = roles.map((role) => ({
    ...role,
    isActive: role.isActive === true ? "Yes" : "No",
  }));

  return (
    <NameSearchableDataTable
      rows={rolesTableData}
      pagingParams={{
        page: 0,
        size: 10,
        filterParams: { filterQuery: null, filterColumn: "name" },
        sortParams: { sortOrder: "asc", sortField: null },
      }}
      tableHeaders={tableHeaders}
      tableColumns={tableColumns}
    />
  );
};

export default RolesTable;
