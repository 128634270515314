import BaseHeader from "../../../shared/baseHeader";
import Title from "../../../shared/title";
import ServerTabs from "./ServerTabs";

export type PropTypes = {
  name: string;
  onClickTab: (path: string) => void;
};

const ServerHeader = ({ name, onClickTab }: PropTypes): JSX.Element => {
  return (
    <BaseHeader
      onClickTab={onClickTab}
      render={(activePath, onClick) => (
        <>
          <Title title={name} />
          <ServerTabs activePath={activePath} onClick={onClick} />
        </>
      )}
    />
  );
};

export default ServerHeader;
