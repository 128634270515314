import { useHistory } from "react-router";
import { useState } from "react";
import {
  Group,
  PagedGroups,
  QueryKeys,
  Server,
  SortParams,
  PagingParams,
  FilterParams,
} from "../../../types";
import Groups from "../../../shared/groups";
import DataLoadingContainer from "../../../shared/dataLoadingContainer";

type PropTypes = {
  serverId: string;
  getServerGroups: (
    serverId: string,
    pagingParams: PagingParams
  ) => Promise<PagedGroups>;
};
const GroupsContainer = ({
  serverId,
  getServerGroups,
}: PropTypes): JSX.Element => {
  const [pagingParams, setPagingParams] = useState<PagingParams>({
    page: 0,
    size: 10,
    filterParams: { filterQuery: null, filterColumn: "name" },
    sortParams: { sortOrder: "asc", sortField: null },
  });

  const history = useHistory();
  const onGroupClicked = (group: Group) => {
    history.push(`/groups/${group.id}/locations`);
  };

  const onServerClicked = (server: Server) => {
    history.push(`/servers/${server.id}`);
  };

  const handlePageChange = (newPage: number, newSize: number) => {
    setPagingParams({ ...pagingParams, page: newPage, size: newSize });
  };

  const handleSortChange = (sortParams: SortParams) => {
    setPagingParams({ ...pagingParams, sortParams });
  };

  const handleFilterChange = (filterParams: FilterParams) => {
    setPagingParams({ ...pagingParams, filterParams, page: 0 });
  };

  const queryKeys = [
    QueryKeys.GetServerGroups,
    serverId,
    pagingParams.page,
    pagingParams.size,
    pagingParams.sortParams.sortField,
    pagingParams.sortParams.sortOrder,
    pagingParams.filterParams.filterQuery,
    pagingParams.filterParams.filterColumn,
  ];
  const queryFn = () => getServerGroups(serverId, pagingParams);

  return (
    <DataLoadingContainer
      queryKeys={queryKeys}
      queryFn={queryFn}
      render={(data: PagedGroups) => {
        return (
          <Groups
            onGroupClicked={onGroupClicked}
            onServerClicked={onServerClicked}
            groupsData={data}
            pagingParams={pagingParams}
            handlePageChange={handlePageChange}
            handleSortChange={handleSortChange}
            handleFilterChange={handleFilterChange}
          />
        );
      }}
    />
  );
};

export default GroupsContainer;
