/* eslint-disable react/require-default-props */
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHeader from "./tableComponents/TableHeader";
import TableRows from "./tableComponents/TableRows";

import {
  FilterParams,
  PagingParams,
  SortParams,
  TableHeaderParams,
} from "../../types";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    height: "100%",
    marginTop: "20px",
  },
});

export type SearchableDataTableProps<T> = {
  rows: T[];
  tableHeaders: TableHeaderParams[];
  pagingParams?: PagingParams;
  tableColumns: string[];
  changeSearchQuery: (filterParams: FilterParams) => void;
  rowsPer?: number;
  tableLength?: number;
  changePagination?: (pageNumber: number, pageSize: number) => void;
  changeSort?: (sortParams: SortParams) => void;
};

export default function SearchableDataTable<T>({
  rows = [],
  tableHeaders,
  pagingParams,
  tableColumns,
  changeSearchQuery,
  tableLength = rows.length,
  changePagination,
  changeSort,
}: SearchableDataTableProps<T>): JSX.Element {
  const classes = useStyles();

  const handleChangePage = (event: unknown, newPage: number) => {
    // setPage(newPage);
    changePagination(newPage, pagingParams.size);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const numberOfRowsPerPages = +event.target.value;
    changePagination(0, numberOfRowsPerPages);
  };

  return (
    <>
      {/* <SearchBar changeSearchQuery={changeSearchQuery} /> */}

      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          aria-label="sticky table"
          className="access-table"
          role="table"
        >
          <TableHeader
            tableHeaders={tableHeaders}
            sortParams={pagingParams?.sortParams}
            filterParams={pagingParams.filterParams}
            changeSort={changeSort}
            changeSearchQuery={changeSearchQuery}
            key="header"
          />
          <TableRows
            page={pagingParams?.page}
            rowsPerPage={pagingParams?.size}
            rows={rows}
            columns={tableColumns}
            key="header-row"
          />
        </Table>
        {pagingParams && (
          <TablePagination
            data-testid="table-pagination"
            rowsPerPageOptions={[4, 10, 15]}
            count={tableLength}
            rowsPerPage={pagingParams?.size}
            page={pagingParams?.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            component="div"
          />
        )}
      </TableContainer>
    </>
  );
}
