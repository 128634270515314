import { Box } from "@mui/material";
import { useHistory } from "react-router";
import { AdminGroup, GroupAccess, QueryKeys, Server } from "../../../types";
import Access from "../../../shared/access";
import DataLoadingContainer from "../../../shared/dataLoadingContainer";

type PropTypes = {
  groupId: string;
  getGroupAccess: (groupId: string) => Promise<GroupAccess>;
};
const AccessContainer = ({
  groupId,
  getGroupAccess,
}: PropTypes): JSX.Element => {
  const history = useHistory();
  const onAdminGroupSelected = (selected: AdminGroup) => {
    history.push(`/adminGroups/${selected.id}`);
  };
  const onServerSelected = (selected: Server) => {
    history.push(`/servers/${selected.id}`);
  };
  const queryKeys = [QueryKeys.GetGroupAccess, groupId];
  const queryFn = () => getGroupAccess(groupId);

  return (
    <DataLoadingContainer
      queryKeys={queryKeys}
      queryFn={queryFn}
      render={(data: GroupAccess) => {
        const { adminUsers, adminGroups } = data;

        return (
          <Box paddingTop={2}>
            <Access
              adminUsers={adminUsers}
              adminGroups={adminGroups}
              onAdminGroupSelected={onAdminGroupSelected}
              onServerSelected={onServerSelected}
            />
          </Box>
        );
      }}
    />
  );
};

export default AccessContainer;
