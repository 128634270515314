import { useState } from "react";
import Button from "@mui/material/Button";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import useStyles from "./styles";

type CopyToClipboardButtonProps = {
  text: string;
  show: boolean;
};

const CopyToClipboardButton = ({
  text,
  show,
}: CopyToClipboardButtonProps): JSX.Element => {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);

  const handleCopyClick = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2500);
  };

  return (
    <div style={{ visibility: show ? "visible" : "hidden" }}>
      <Button
        variant="text"
        startIcon={<ContentCopyIcon />}
        disableRipple
        disableTouchRipple
        disableFocusRipple
        disableElevation
        focusRipple={false}
        data-testid="copy-to-clipboard-button"
        endIcon={copied ? <CheckIcon style={{ fontSize: "11px" }} /> : ""}
        onClick={handleCopyClick}
        size="small"
        classes={{
          endIcon: classes.endIcon,
          startIcon: classes.startIcon,
          root: classes.container,
        }}
      />
    </div>
  );
};

export default CopyToClipboardButton;
