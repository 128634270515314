import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";

type BackToButtonProps = {
  sendToRoute: string;
  sendToTitle: string;
};
const BackButton = ({
  sendToRoute,
  sendToTitle,
}: BackToButtonProps): JSX.Element => {
  const history = useHistory();

  return (
    <>
      <br />
      <Button
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        size="small"
        style={{ marginBottom: 5 }}
        onClick={() => history.push(`/${sendToRoute}`)}
        data-testid={`back-button-${sendToTitle}`}
      >
        Back to {sendToTitle}
      </Button>
    </>
  );
};

export default BackButton;
