import SimpleTabs, { TabConfig } from "../../../shared/simpleTab";

type PropTypes = {
  activePath: string;
  onClick: (clickedTab: TabConfig) => void;
};

const RootTabs = ({ activePath, onClick }: PropTypes): JSX.Element => {
  const tabConfigs = [
    {
      label: "Stacks",
      path: "servers",
      disabled: false
    },
    // {
    //   label: "Access",
    //   path: "access",
    //   disabled: true
    // },
    {
      label: "Groups",
      path: "groups",
      disabled: false
    },
    // {
    //   label: "Integrations",
    //   path: "integrations",
    //   disabled: false
    // },
    // {
    //   label: "Global Settings",
    //   path: "globalSettings",
    //   disabled: false
    // },
  ];

  return (
    <SimpleTabs
      tabConfigs={tabConfigs}
      activePath={activePath}
      onClick={onClick}
    />
  );
};

export default RootTabs;
