import { Box } from "@mui/material";
import { useHistory } from "react-router";
import { AdminGroup, QueryKeys, Server, ServerAccess } from "../../../types";
import Access from "../../../shared/access";
import DataLoadingContainer from "../../../shared/dataLoadingContainer";

type PropTypes = {
  serverId: string;
  getServerAccess: (serverId: string) => Promise<ServerAccess>;
};
const AccessContainer = ({
  serverId,
  getServerAccess,
}: PropTypes): JSX.Element => {
  const history = useHistory();
  const onAdminGroupSelected = (selected: AdminGroup) => {
    history.push(`/adminGroups/${selected.id}`);
  };

  const onServerSelected = (selected: Server) => {
    history.push(`/servers/${selected.id}`);
  };

  const queryKeys = [QueryKeys.GetServerAccess, serverId];
  const queryFn = () => getServerAccess(serverId);

  return (
    <DataLoadingContainer
      queryKeys={queryKeys}
      queryFn={queryFn}
      render={(data: ServerAccess) => {
        const { adminUsers, adminGroups } = data;

        return (
          <Box paddingTop={2}>
            <Access
              adminUsers={adminUsers}
              adminGroups={adminGroups}
              onAdminGroupSelected={onAdminGroupSelected}
              onServerSelected={onServerSelected}
            />
          </Box>
        );
      }}
    />
  );
};

export default AccessContainer;
