import { LinearProgress } from "@mui/material";
import { ReactNode } from "react";
import { useQuery } from "react-query";
import { QueryKeys } from "../../types";

type PropTypes<T> = {
  queryKeys: string[] | (number | QueryKeys)[] | (string | number)[];
  queryFn: () => Promise<T>;
  render: (data: T) => ReactNode;
};

const DataLoadingContainer = <T extends unknown>({
  queryKeys,
  queryFn,
  render,
}: PropTypes<T>): JSX.Element => {
  const { isLoading, data } = useQuery<T>(queryKeys, queryFn, {
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return (
      <LinearProgress data-testid="linear-progress" style={{ marginTop: -3 }} />
    );
  }

  return <>{render(data)}</>;
};

export default DataLoadingContainer;
