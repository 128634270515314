import SimpleTabs, { TabConfig } from "../../../shared/simpleTab";

type PropTypes = {
  activePath: string;
  onClick: (clickedTab: TabConfig) => void;
};

const GroupTabs = ({ activePath, onClick }: PropTypes): JSX.Element => {
  const tabConfigs = [
    {
      label: "Locations",
      path: "locations",
      disabled: false,
    },
    {
      label: "Access",
      path: "access",
      disabled: true,
    },
    {
      label: "Settings",
      path: "settings",
      disabled: true,
    },
    {
      label: "Integrations",
      path: "integrations",
      disabled: true,
    },
    {
      label: "Info",
      path: "info",
      disabled: true,
    },
  ];

  return (
    <SimpleTabs
      tabConfigs={tabConfigs}
      activePath={activePath}
      onClick={onClick}
    />
  );
};

export default GroupTabs;
