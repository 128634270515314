import BaseHeader from "../../../shared/baseHeader";
import Title from "../../../shared/title";
import AdminGroupTabs from "./AdminGroupTabs";

export type PropTypes = {
  name: string;
  onClickTab: (path: string) => void;
};

const AdminGroupHeaders = ({ name, onClickTab }: PropTypes): JSX.Element => {
  return (
    <BaseHeader
      onClickTab={onClickTab}
      render={(activePath, onClick) => (
        <>
          <Title title={name} />
          <AdminGroupTabs activePath={activePath} onClick={onClick} />
        </>
      )}
    />
  );
};

export default AdminGroupHeaders;
