import { Box } from "@mui/material";
import DataLoadingContainer from "../../shared/dataLoadingContainer";
import { GlobalSetting, QueryKeys } from "../../types";
import GlobalSettings from "./GlobalSettings";

type PropTypes = {
  getGlobalSettings: () => Promise<GlobalSetting[]>;
};

const GlobalSettingsContainer = ({
  getGlobalSettings,
}: PropTypes): JSX.Element => {
  const queryKeys = [QueryKeys.GetGlobalSettings];
  const queryFn = getGlobalSettings;

  return (
    <DataLoadingContainer
      queryKeys={queryKeys}
      queryFn={queryFn}
      render={(data: GlobalSetting[]) => (
        <Box paddingTop={2}>
          <GlobalSettings globalSettings={data} />
        </Box>
      )}
    />
  );
};

export default GlobalSettingsContainer;
