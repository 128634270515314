import { useHistory } from "react-router";
import { Box } from "@mui/material";
import { Access, AdminGroup, QueryKeys, Server } from "../../types";
import AccessComponent from "./Access";
import DataLoadingContainer from "../../shared/dataLoadingContainer";

type PropTypes = {
  getAccess: () => Promise<Access>;
};

const AccessContainer = ({ getAccess }: PropTypes): JSX.Element => {
  const history = useHistory();
  const onAdminGroupSelected = (selected: AdminGroup) => {
    history.push(`/adminGroups/${selected.id}`);
  };
  const onServerSelected = (selected: Server) => {
    history.push(`/servers/${selected.id}`);
  };

  const queryFn = getAccess;
  const queryKeys = [QueryKeys.GetAccess];

  return (
    <DataLoadingContainer
      queryFn={queryFn}
      queryKeys={queryKeys}
      render={({ adminGroups, adminUsers }: Access) => (
        <Box paddingTop={2}>
          <AccessComponent
            adminGroups={adminGroups}
            adminUsers={adminUsers}
            onAdminGroupSelected={onAdminGroupSelected}
            onServerSelected={onServerSelected}
          />
        </Box>
      )}
    />
  );
};

export default AccessContainer;
