import "./index.css";

type PropTypes = {
  serverStatus: string;
};

const ServerStatusCircle = ({ serverStatus }: PropTypes): JSX.Element => {
  return (
    <div className={serverStatus === "Good" ? "green-circle" : "red-circle"} />
  );
};

export default ServerStatusCircle;
