import { useState } from "react";
import { ButtonGroup } from "@mui/material";
import SearchableDataTable from "../../../shared/searchableTable";
import {
  PagedLocations,
  PagingParams,
  SortParams,
  TableHeaderParams,
  FilterParams,
} from "../../../types";
import BrinkTypography from "../../../shared/brinkTypography";
import CopyToClipboardButton from "../../../shared/copyToClipboardButton";
import { RefEntity, buildUrl } from "../../../shared/urlBuilder/adminPortal";

type PropTypes = {
  pagedLocations: PagedLocations;
  pagingParams: PagingParams;
  handlePageChange: (page: number, size: number) => void;
  handleSortChange: (sortParams: SortParams) => void;
  handleFilterChange: (filterParams: FilterParams) => void;
};

const tableHeaders: TableHeaderParams[] = [
  { title: "Name", sortable: true, filterable: true, field: "name" },
  { title: "Location ID", sortable: true, field: "id" },
  // { title: "Bus. Date", sortable: true, field: "businessDate" },
  // { title: "DB Name", sortable: false, field: "dbName" },
  // { title: "Online Terminals", sortable: false, field: "onlineTerminalsCount" }
];

const tableColumns = [
  "name",
  "id",
  // "businessDate",
  // "dbName",
  // "onlineTerminalsCount",
];

const Locations = ({
  pagedLocations,
  pagingParams,
  handlePageChange,
  handleSortChange,
  handleFilterChange,
}: PropTypes): JSX.Element => {
  const { locations, totalCount } = pagedLocations;

  const changeSearchQuery = (filterParams: FilterParams) => {
    handleFilterChange(filterParams);
  };

  const [hoveringLocationId, setHoveringLocationId] = useState(null);

  const handleMouseOver = (locationId: string) => {
    setHoveringLocationId(locationId);
  };

  const handleMouseOut = () => {
    setHoveringLocationId(null);
  };

  const locationsData = locations.map((location) => ({
    ...location,
    name: () => {
      return (
        <span
          data-testid={`server-link-${location.id}`}
          role="button"
          tabIndex={0}
        >
          <a
            href={buildUrl(
              RefEntity.locations,
              location.serverName,
              location.groupId,
              location.id
            )}
            target="_blank"
            rel="noreferrer"
          >
            {location.name}
          </a>
        </span>
      );
    },
    id: () => {
      return (
        <span
          onMouseOver={() => handleMouseOver(location.id)}
          onFocus={() => handleMouseOver(location.id)}
          onMouseOut={() => handleMouseOut()}
          onBlur={() => handleMouseOut()}
        >
          <ButtonGroup variant="contained">
            <BrinkTypography>{location.id}</BrinkTypography>
            <CopyToClipboardButton
              text={location.name}
              show={hoveringLocationId === location.id}
            />
          </ButtonGroup>
        </span>
      );
    },
  }));

  return (
    <SearchableDataTable
      rows={locationsData}
      tableColumns={tableColumns}
      pagingParams={pagingParams}
      tableHeaders={tableHeaders}
      changePagination={handlePageChange}
      changeSort={handleSortChange}
      tableLength={totalCount}
      changeSearchQuery={changeSearchQuery}
    />
  );
};

export default Locations;
