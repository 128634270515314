import { filter } from "lodash";
import { useState, useEffect } from "react";
import {
  SortParams,
  UniqueEntity,
  TableHeaderParams,
  PagingParams,
  FilterParams,
} from "../../types";
import SearchableDataTable from "../searchableTable";

type PropTypes<T> = {
  rows: T[];
  tableHeaders: TableHeaderParams[];
  pagingParams?: PagingParams;
  tableColumns: string[];
  rowsPer?: number;
  tableLength?: number;
  changePagination?: (pageNumber: number, pageSize: number) => void;
  changeSort?: (sortParams: SortParams) => void;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NameSearchableDataTable = <T extends UniqueEntity>({
  rows = [],
  tableHeaders,
  pagingParams,
  tableColumns,
  rowsPer = 10,
  tableLength = rows.length,
  changePagination,
  changeSort,
}: PropTypes<T>): JSX.Element => {
  const [rowsState, setRowsState] = useState(rows);
  const changeSearchQuery = (filterParams: FilterParams) => {
    // const filteredRows = filter(rows, ({ name }) => {
    //   return name.includes(query);
    // });
    // setRowsState(filteredRows);
  };

  useEffect(() => {
    setRowsState(rows);
  }, [pagingParams]);

  return (
    <SearchableDataTable
      rows={rowsState}
      tableHeaders={tableHeaders}
      pagingParams={pagingParams}
      tableColumns={tableColumns}
      changeSearchQuery={changeSearchQuery}
      rowsPer={rowsPer}
      tableLength={tableLength}
      changePagination={changePagination}
      changeSort={changeSort}
    />
  );
};

export default NameSearchableDataTable;
